import api from "../api";

export const deleteOwnerVerifyText = async (id) => {

    try {
        const users = await api.delete(`/owner_site/shops/orders/text-records/${id}/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}