import React, {useEffect, useState} from 'react';
import styles from './ModeratorSubscribe.module.scss'
import rootStore from "../../store/RootStore";
import AcceptModal from "../AcceptModal/AcceptModal";
import {observer} from "mobx-react";
import {Alert} from "@mui/material";
import {getCurrencySymbol} from "../../helpers/getCurrency";
import {t} from "i18next";

const ModeratorSubscribe = () => {
    const symbol = getCurrencySymbol();
    const [sub, setSub] = useState(true);
    const [openAccept, setOpenAccept] = useState(false);

    const {checkSubscribe, getSubscribeData, buySubscribe, getStatusBuy, setStatusBuy} = rootStore.userStore;

    useEffect(() => {
        checkSubscribe()
    }, [])

    const handleActive = () => {
        setOpenAccept(true)
    }

    return (
        <>
            <div className={styles.mainBlock}>
                <div className={styles.personSubInfoBlock}>
                    <div className={styles.infoSubConteiner}>
                        <div className={styles.infoRow}>{t("Subscription status")}:</div>
                        <div className={`${styles.infoRowA} ${getSubscribeData.is_active ? styles.active : styles.inactive}`}>{getSubscribeData.is_active ? t("Active") : t("Inactive")}</div>
                        <div className={styles.infoRow}>{t("Subscription end date")}:</div>
                        <div className={styles.infoRowA}>{getSubscribeData.subscription_expiry || t("Inactive date")}</div>
                    </div>
                </div>
                <div className={styles.infoSubTab}>
                    <div className={styles.titleRow}>{t("Subscribe info")}</div>
                    <div className={styles.costSub}>{getSubscribeData.seller_deposit} {symbol}</div>
                    <div className={styles.infoForStore}>{t("Store commission")}: {getSubscribeData.transaction_commission}%</div>
                    <div className={styles.infoForStore}>{t("More info in this block")}</div>
                    <div className={styles.infoForStore}>{t("And info for shop master")}</div>
                    <div className={styles.infoForStore}>{t("And more info for master")}</div>
                    <button className={styles.btnBuy} onClick={() => handleActive()}>{t("Buy")}</button>
                </div>
                { getStatusBuy && (<Alert sx={{marginTop: "1rem"}} severity="info">{getStatusBuy}</Alert>)}
            </div>

            { openAccept &&
                (<AcceptModal
                handleClose={()=>setOpenAccept(false)}
                setState={setOpenAccept}
                action={true}
                handleSubmit={buySubscribe}
            />) }
        </>

    );
};

export default observer(ModeratorSubscribe);