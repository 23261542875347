import React, { useEffect, useState } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./ModeratorNavbar.module.scss"

import MENU from "../../constants/constantMenu";
import rootStore from "../../store/RootStore";
import IconSettings from "../Icons/SettingsIcon";
import IconArrowBack from "../Icons/IconArrowBack";
import IconStatistic from "../Icons/IconStatistic";
import HistoryOrders from "../../pages/CurierPanel/static/HistoryOrders";
import IconBasketFill from "../Icons/IconBacket";
import IconTruckDeliveryOutline from "../Icons/IconTruckDeliveryOutline";
import { getActivNavModer, setActivNavModer } from "../../helpers";

const ModeratorNavbar = () => {
    const navigate = useNavigate();
    
    const [buttonNum, setButtonNum] = useState(2);

    const shops = rootStore.superUserStore.superUserShopsList;
    const { setIsOpenSettingShop,setActivePanel } = rootStore.moderatorPanelStore;

    const handeSetting = (prop, num) => {
        setButtonNum(num)
        setIsOpenSettingShop(MENU.Setting === prop)
        setActivePanel(prop)
        setActivNavModer(prop)
        navigate(prop.toLocaleLowerCase())
    }

    useEffect(() => {
        const stateModer = JSON.parse(getActivNavModer());
        if (stateModer === "Statistics") {
            handeSetting(MENU.Statistics, 1)
        } else if (stateModer === "Menu") {
            handeSetting(MENU.Menu, 2)
        } else if (stateModer === "Curiers") {
            handeSetting(MENU.Curiers, 3)
        } else if (stateModer === "Orders") {
            handeSetting(MENU.Orders, 5)
        } else if (stateModer === "Setting") {
            handeSetting(MENU.Setting, 4)
        }
    }, []);

    const handeSetCurier = () => {
        if (shops.length){
            handeSetting(MENU.Curiers, 3)
        }
        navigate("curiers")
    }

    return (
            <>
                <div className={styles.navigation}>
                    <div className={styles.navigationBody}>
                        <div className={`${styles.linkFirst} ${buttonNum === 1 && styles.activBtn}`}
                             onClick={() => handeSetting(MENU.Statistics, 1)}>
                            <div className={styles.linkFirstText}>
                                <IconStatistic/>
                                <span className={buttonNum === 1 && styles.activBtn}>{t("Statistics")}</span>
                            </div>
                        </div>
                        <div onClick={() => handeSetting(MENU.Menu, 2)} id="productsNavigationTab"
                             data-navigation="true"
                             className={`${styles.linkFirst} ${buttonNum === 2 && styles.activBtn}`}>
                            <div className={styles.linkFirstText}>
                                <IconBasketFill/>
                                <span className={buttonNum === 2 && styles.activBtn}>{t("Products")}</span>
                            </div>
                        </div>
                        <div className={`${styles.linkFirst} ${buttonNum === 3 && styles.activBtn}`}
                             onClick={() => handeSetCurier()}>
                            <div className={styles.linkFirstText}>
                                <IconTruckDeliveryOutline/>
                                <span className={buttonNum === 3 && styles.activBtn}>{t("Courier")}</span>
                            </div>
                        </div>
                        <div className={`${styles.linkFirst} ${buttonNum === 5 && styles.activBtn}`}
                             onClick={() => handeSetting(MENU.Orders, 5)}>
                            <div className={styles.linkFirstText}>
                                <HistoryOrders fill={"#1b5e3b"}/>
                                <span className={buttonNum === 5 && styles.activBtn}>{t("Orders")}</span>
                            </div>
                        </div>
                        <div className={`${styles.linkFirst} ${buttonNum === 4 && styles.activBtn}`}
                             onClick={() => handeSetting(MENU.Setting, 4)}
                             data-testid="settingsTab">
                            <div className={styles.linkFirstText}>
                                <IconSettings/>
                                <span className={buttonNum === 4 && styles.activBtn}>{t("Settings")}</span>
                            </div>
                        </div>
                        <div className={styles.linkFirst} onClick={() => navigate("/discovery/me")}
                             data-testid="settingsTab">
                            <div className={styles.linkFirstText}>
                                <IconArrowBack/>
                                <span>{t("Back")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default observer(ModeratorNavbar);