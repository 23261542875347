import api from "../api";

export const deleteOwnerSearchTag = async (id) => {
    console.log(id)
    try {
        const users = await api.delete(`/owner_site/shops/search/o/tags/${id}/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}