import React, {useEffect, useState} from 'react';
import styles from "./AddresPage.module.scss"
import stylesA from "./AddressInfo.module.scss"
import rootStore from "../../store/RootStore";
import {useNavigate} from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    styled,
    TextField
} from "@mui/material";
import {observer} from "mobx-react";
import { BiSolidTrash } from "react-icons/bi";
import "./AddresPageMui.scss"
import {t} from "i18next";
import useIsMobile from "../../helpers/useIsMobile";

const CITY_DATA = ["Tel Aviv", "Ba'Er Sheva", "Ashdod", "Ashkelon", "Nazareth", "Tiberias", "Tzfat", "Hevron"]

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;

const AddresPage = () => {
    const { adrressesList, loadAdressesList, saveNewAddress, setUserAddresMain, deleteNewAddress, editAddressRequest } = rootStore.addressStore;
    const { getProfile, userData } = rootStore.userStore;

    const [newAddress, setNewAddress] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");

    const [idEditAddress, setIdEditAddress] = useState(0);
    const [textDistrict, setTextDistrict] = useState("");
    const [textStreet, setTextStreet] = useState("")
    const [textName, setTextName] = useState("")
    const [textHouse, setTextHouse] = useState("")
    const [textEntr, setTextEntr] = useState("")
    const [textCity, setTextCity] = useState("Tel Aviv")
    const [focused, setFocused] = useState(false);
    const { setNavBarHidden} = rootStore.systemStore;

    const isMobile = useIsMobile()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseConfirm = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setTextCity(e.target.value)
    }

    const navigate = useNavigate();
    useEffect(() => {
        const element = document.getElementById('root');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);

    useEffect(() => {
        loadAdressesList()
    }, []);

    const handleClickAdd = (isBack) => {
        if (isBack && !newAddress && !editAddress) {
            navigate('/discovery/me', { replace: true });
        } else {
            setNewAddress(false)
            setEditAddress(false)
        }
    }
    const handleClick = () => {
        if (editAddress) {
            const data = {
                "country": "Israel",
                "city": textCity,
                "district": textDistrict,
                "street": textStreet,
                "house_number": textHouse,
                "entrance_number": textEntr,
                "coordinates": "32.0708, 34.7836",
                "address_number": 123,
                "label": textName
            }
            editAddressRequest(idEditAddress, data)
            setTimeout(() => {
                loadAdressesList()
            }, 250)
            setEditAddress(false);
        } else if (!newAddress){
            setNewAddress(true);
            setTextDistrict('')
            setTextStreet('')
            setTextName('')
            setTextHouse('')
            setTextEntr('')
            setTextCity("Tel Aviv")
            setEditAddress('')
            setIdEditAddress('')
        } else {
            const data = {
                "country": "Israel",
                "city": textCity,
                "district": textDistrict,
                "street": textStreet,
                "house_number": textHouse,
                "entrance_number": textEntr,
                "coordinates": "32.0708, 34.7836",
                "address_number": 123,
                "label": textName
            }
            saveNewAddress(data)
            setTimeout(() => {
                loadAdressesList()
                setNewAddress(false);
                navigate("/discovery/me/my-addresses")
            }, 250)
        }
    }

    const deleteAddres = (id) => {
        deleteNewAddress(id)
        setTimeout(() => {
            loadAdressesList()
            setNewAddress(false)
            setIsOpenModel(false)
            navigate("/discovery/me/my-addresses")
        }, 200)
        handleClickAdd(true)
        handleCloseConfirm()
    }

    const handleSetUserAddresMain = (address) => {
        setIsOpenModel(false)
        setUserAddresMain(address.id)
        setTimeout(() => {
            getProfile()
        }, 100)
    }

    const handleClickEdit = (address) => {
        setIsOpenModel(false)
        setTextDistrict(address.district)
        setTextStreet(address.street)
        setTextName(address.label)
        setTextHouse(address.house_number)
        setTextEntr(address.entrance_number)
        setTextCity(address.city)
        setEditAddress(address)
        setIdEditAddress(address.id)
    }

    const isActive = textDistrict && textStreet && textName && textHouse && textEntr && textCity

    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
    }

    const StyledMenuItem = styled(MenuItem)`
        &.MuiButtonBase-root{
            padding-left: 10px !important; /* пример стиля для MuiMenuItem-gutters */
        }
        &.MuiPaper-root{
            border-radius: 10px !important; /* пример стиля для MuiMenuItem-gutters */
        }
`;
    function scrollToBottom() {
        setFocused(true)
        window.scrollTo(0, document.body.scrollHeight);
    }
    const handleInputFocus = () => {
        scrollToBottom()
        setNavBarHidden(true);
        setFocused(true)
    }
    const handleInputBlur = () => {
        setNavBarHidden(false);
        setFocused(false)
    }
    return (
        <div className={styles.mainConteiner}>
            <>
                {/*<Button variant="outlined" onClick={handleClickOpen}>*/}
                {/*    Slide in alert dialog*/}
                {/*</Button>*/}
                <StyledDialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{t("Warning")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t("Are you sure you want to delete the address?")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                        <Button onClick={()=>deleteAddres(idEditAddress)}>{t("Confirm")}</Button>
                    </DialogActions>
                </StyledDialog>
            </>
            {isMobile && <div style={{
                "position": "fixed",
                "width": "32px",
                "height": "32px",
                "zIndex": 999999,
                "top": "1%",
                "left": "3%",
            }}
                  onClick={() => handleClickAdd(true)}><IconBack size={20} pure={true} fill={"#1b5e3b"}/></div>}
            <h2 className={styles.mainTitle}>{newAddress ? t("Add new address") : (editAddress ? t("Edit address") : t("Saved Address"))}</h2>
            {editAddress && <div className={styles.deleteIcon} onClick={ handleClickOpen}  >
                <BiSolidTrash fontSize='large' fill="var(--text-color)"/>
            </div>}

            <div className={styles.profileCard}>
                {
                    newAddress || editAddress ?
                    (
                        <div className={styles.blockMainInfo}>
                            <div>
                                <div className={stylesA.imagesBlock}>
                                    <div className={stylesA.rowBlock}>
                                        <div className={stylesA.leftBlock}>
                                            <h2 className={stylesA.cityTitle}>{t("City")}:</h2>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t("City")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={textCity}
                                                    label={t("City")}
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        CITY_DATA.map(city => (
                                                            <StyledMenuItem value={city}>{city}</StyledMenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className={stylesA.titleBody}>{t("District")}</h2>
                            <span className={styles.descriptionText}>{t("The district type helps us to find you better.")}</span>
                            <TextField
                                id="outlined-helperText1"
                                label={t("District name")}
                                defaultValue={textDistrict}
                                onChange={(e) => setTextDistrict(e.target.value)}
                                sx={{width: "100%", borderRadius: "1rem", marginTop: "0.85rem"}}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                            />
                            <h2 className={stylesA.titleBody2}>{t("Address details")}</h2>
                            <div className={stylesA.imgBlocksSearch}>
                                <TextField
                                    id="outlined-helperText1"
                                    label={t("Street name")}
                                    defaultValue={textStreet}
                                    onChange={(e) => setTextStreet(e.target.value)}
                                    sx={{width: "100%", borderRadius: "1rem"}}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                />
                                <span className={stylesA.optionalText2}>House info</span>
                                <div className={stylesA.downFields}>
                                    <TextField
                                        id="outlined-helperText3"
                                        label={t("House number")}
                                        defaultValue={textHouse}
                                        onChange={(e) => setTextHouse(e.target.value)}
                                        sx={{borderRadius: "1rem", width: "48%"}}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                    />
                                    <TextField
                                        id="outlined-helperText4"
                                        label={t("Entrance number")}
                                        defaultValue={textEntr}
                                        onChange={(e) => setTextEntr(e.target.value)}
                                        sx={{borderRadius: "1rem", width: "48%"}}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                    />
                                </div>
                            </div>
                            <span className={stylesA.optionalText}>{t("Name for save address info")}</span>
                            <div>
                                <TextField
                                    id="outlined-helperText2"
                                    label={t("Name")}
                                    defaultValue={textName}
                                    onChange={(e) => setTextName(e.target.value)}

                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    sx={{
                                        width: "100%",
                                        borderRadius: "1rem",
                                        marginBottom: "1rem",
                                        marginTop: "1rem"
                                    }}
                                />
                            </div>
                            <div>
                                <button
                                    disabled={(newAddress || editAddress) && !isActive}
                                    className={`${styles.saveNewAddress} ${(newAddress || editAddress) ? (!isActive && styles.disable) : ""}`}
                                    onClick={() => handleClick()}>{newAddress || editAddress ? t("Saved Address") : t("Add new address")}</button>
                            </div>

                        </div>
                    ) :
                        (
                            <div className={styles.downConteiner}>
                                {
                                    adrressesList.map(address => (
                                        <div className={styles.imagesBlock}
                                             onClick={() => handleSetUserAddresMain(address)}
                                    >
                                        <div className={styles.rowBlock } >
                                            <div className={styles.leftBlock}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                     className={userData?.profile?.country === address.id ? styles.svgImage__active : styles.svgImage} fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                          d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path fill-rule="evenodd"
                                                          d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                                <div className={styles.textBlockImage}>
                                                    <span
                                                        className={userData?.profile?.country === address.id ? styles.topText__active : styles.topText}>{address.label}</span>
                                                    <span className={styles.downText}>{address.city}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.IconNextBlock}>
                                            <button
                                                onClick={() => handleClickEdit(address)}
                                                className={`${styles.mainStyle2}`}>
                                            <div className={styles["IconBack__icon"]}>
                                                    <svg viewBox="0 0 400 400"
                                                         width={24}
                                                         height={20}
                                                         color={"black"}
                                                         className={styles.mainSvg}>
                                                        <g xmlns="http://www.w3.org/2000/svg">
                                                            <g id="keyboard-control">
                                                                <path
                                                                    d="M51,153c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S79.05,153,51,153z M357,153c-28.05,0-51,22.95-51,51    s22.95,51,51,51s51-22.95,51-51S385.05,153,357,153z M204,153c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51    S232.05,153,204,153z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            {(!newAddress && !editAddress) && <button
                     disabled={(newAddress || editAddress) && !isActive}
                     className={`${styles.addNewAddress} ${(newAddress || editAddress) ? (!isActive && styles.disable) : ""}`}
                     onClick={() => handleClick()}>{newAddress || editAddress ? t("Saved Address") : t("Add new address")}</button>}
        </div>
    );
};

export default observer(AddresPage);