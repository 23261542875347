import api from "../api";

export const addOwnerRole = async (data) => {
    try {
        await api.post(
            `/owner_site/verifications/users/client-status-roles/`,
            data
        )
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
};
