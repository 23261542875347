import React, { useRef, useState } from 'react';
import styles from './FaceModalRecord.module.scss';
import { t } from "i18next";

const FaceModalRecord = ({ setVerificated, setOpenVerificatModal }) => {
    const videoRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [isRecorded, setIsRecorded] = useState(false); // Флаг для отслеживания завершенной записи
    const [isCameraActive, setIsCameraActive] = useState(false); // Состояние для проверки, активна ли камера

    let localRecordedChunks = [];

    // Старт записи видео
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });

            // Отображаем видео на экране
            videoRef.current.srcObject = stream;
            videoRef.current.play();

            // Создаем MediaRecorder для записи
            const recorder = new MediaRecorder(stream);

            // Слушаем события записи
            recorder.ondataavailable = (event) => {
                localRecordedChunks.push(event.data); // Локально сохраняем данные
            };

            recorder.onstop = () => {
                // После остановки записи собираем все данные
                const blob = new Blob(localRecordedChunks, { type: 'video/webm' });
                const videoFile = new File([blob], 'recorded-video.webm', { type: 'video/webm' });
                setVerificated(videoFile); // Отправляем готовое видео в родительский компонент
                console.log(videoFile);
                setIsRecorded(true); // Помечаем запись как завершенную
            };

            setMediaRecorder(recorder);
            recorder.start();

            setIsRecording(true);
            setIsCameraActive(true); // Включаем состояние камеры

            // Автоматическая остановка записи через 20 секунд
            setTimeout(() => {
                stopRecording();
            }, 20000); // 20 секунд
        } catch (error) {
            console.error('Ошибка при доступе к камере или микрофону:', error);
        }
    };

    // Остановка записи
    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    // Перезапуск записи
    const restartRecording = () => {
        localRecordedChunks = [];
        setRecordedChunks([]); // Очистить previous recordedChunks
        setIsRecorded(false); // Сбросить статус завершенной записи
        setIsCameraActive(false); // Сбросить состояние камеры (картинка снова отобразится)
        startRecording(); // Начать новую запись
    };

    return (
        <div className={styles.container}>
            <div className={styles.videoWrapper}>
                {/* Картинка, которая отображается до включения камеры */}
                {!isCameraActive && (
                    <div className={styles.imageWrapper}>
                        <img
                            src="http://localhost:3000/static/media/person-line.8b06fd8b7d15cfd8874a.svg"
                            alt="Placeholder"
                            className={styles.placeholderImage}
                        />
                    </div>
                )}

                {/* Видеофрейм, который показывается, когда камера активна */}
                <video ref={videoRef} muted className={styles.video}></video>
            </div>

            <div className={styles.controls}>
                {!isRecording && !isRecorded ? (
                    <button onClick={startRecording} className={styles.recordButton}>
                        <span className={styles.recordIcon}></span>
                        {t("Start Recording")}
                    </button>
                ) : isRecording ? (
                    <button onClick={stopRecording} className={styles.stopButton}>
                        <span className={styles.stopIcon}></span>
                        {t("Stop Recording")}
                    </button>
                ) : (
                    <button onClick={restartRecording} className={styles.restartButton}>
                        <span className={styles.restartIcon}></span>
                        {t("Restart Recording")}
                    </button>
                )}
                {isRecorded && (
                    <button onClick={() => setOpenVerificatModal(false)} className={styles.footerBtn}>
                        {t("Back to checkout!")}
                    </button>
                )}
            </div>
        </div>
    );
};

export default FaceModalRecord;
