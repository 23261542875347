import api from "../api";

export const updateOwnerRoleInfo = async (data, id) => {
    console.log(id)
    try {
        const users = await api.patch(`/owner_site/verifications/users/client-status-roles/${id}/`, data);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}