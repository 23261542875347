import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import {useState} from "react";
import { LuSmilePlus } from "react-icons/lu";
import { PiSmileyMeh } from "react-icons/pi";
import { FaRegSmileBeam } from "react-icons/fa";
import { CiFaceSmile } from "react-icons/ci";
import { PiSmileySadLight } from "react-icons/pi";
import { PiSmileyAngryLight } from "react-icons/pi";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty': {
        color: 'var(--text-color)', // Set the empty icon color to be the same as the filled icon color
    },
    '& .MuiRating-iconFilled': {
        color: '#1b5e3b', // Set the empty icon color to be the same as the filled icon color
    },
    //
}));

const customIcons = {
    1: {
        icon: <>😃</>,
        label: 'Very Good',
    },
    2: {
        icon: <>🙂</>,
        label: 'Good',
    },
    3: {
        icon: <>😑</>,
        label: 'Neutral',
    },
    4: {
        icon: <>😞</>,
        label: 'Bad',
    },
    5: {
        icon: <>😣</>,
        label: 'Very bad',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{fontSize: '2.7rem',color:"var(--text-color)"}} {...other}>{customIcons[value].icon}</span>
            <div style={{fontSize: '0.7rem', whiteSpace: "nowrap", alignSelf: "center"}} {...other}>{customIcons[value].label}</div>
        </div>
    )
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function RadioGroupRating({handleRatingValue}) {
    const [value, setValue] = useState(4);

    const handleRatingChange = (event, newValue) => {
        setValue(newValue);
        handleRatingValue(newValue);
    };
    return (
        <StyledRating
            name="highlight-selected-only"
            defaultValue={4}
            sx={{gap: "10px"}}
            onChange={handleRatingChange}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
        />
    );
}