import {useEffect, useState} from 'react';

import {
    Box,
    CircularProgress,
    Paper,
    styled,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import {t} from "i18next";
import {getOwnerRolesList} from "../../../api/getContentsApi/getOwnerRolesList";
import {Link, useNavigate, useParams} from "react-router-dom";
import styles from "./AddRolesToUsers.module.scss";
import useIsMobile from "../../../helpers/useIsMobile";
import {addRoleToUser} from "../../../api/addContent/addRoleToUser";
import * as React from "react";

export const AddRolesToUsers = ({action}) => {
    const [roles, setRoles] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const isSelectRole = window.location.pathname.includes('verification/user/')
    const navigate = useNavigate();
    const { userId } = useParams();
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                setLoading(true);
                const data = await getOwnerRolesList();
                console.log(data)
                data.push({
                    "id": 9999999,
                    "name": "Default",
                    "highlight_color": "#13ff00",
                    "verification_authentication": false
                })
                setRoles(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchRoles();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        padding: '7px',
    }));

    const isMobile = useIsMobile();
    const handleClickOnLink = (role) => {
        if(!isSelectRole) {
            navigate(`../role/${role.id}`)
        }
    }
    const handleSelectRole = async (role) => {
        await addRoleToUser({
            "client_status_role": role.id === 9999999 ? null : role.id
        }, window.location.pathname.split("/")[4])
        navigate(-1)
    }
    const handleClickCloseModal = () => {
        action(false)
    }
    console.log(roles)
    return (
        <Paper sx={{width: '100%'}}>
            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>:
                <>
                    <button className={styles.closeImgBtn} onClick={() => {handleClickCloseModal()}}>
                        <svg viewBox="0 0 24 24" className={styles.closeImg}>
                            <path
                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                        </svg>
                    </button>
                    <TableContainer sx={{maxHeight: "60vh"}}>
                        <Table sx={isMobile ? {maxWidth: "100%"} : ""}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{width: "100%"}}>{t("Role")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role) => (
                                    <TableRow key={role.id}>
                                        <StyledTableCell sx={{width: "100%", wordWrap: 'break-word'}}>
                                            <Link className={styles.mainBtnLinkControll} relative onClick={(role) => handleClickOnLink(role)}>
                                            {role.name}
                                                <button className={styles.btnSelect} onClick={() => handleSelectRole(role)}>{t("Select")}</button>
                                            </Link>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 50, 100]}
                        component="div"
                        count={roles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
        </Paper>
    );
};
