import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Slide,
    styled
} from "@mui/material";
import {t} from "i18next";

import LazyLoad from 'react-lazy-load';
import styles from "./SupportUser.module.scss";
import IconBack from "../../../components/Icons/IconBack";
import {getUserInfo} from "../../../api/getContentsApi/getUserInfo";
import {unUnSupported} from "../../../api/getContentsApi/unUnSupported";
import {getSupportChats} from "../../../api/getContentsApi/getSupportChats";
import SupportChatOwner from "../../SupportPage/SupportChatOwner";
import useIsMobile from "../../../helpers/useIsMobile";
import {unknownImage} from "../../../helpers";

const roles = {
    5: "Support",
    4: "Owner",
    3: "Courier",
    2: "Superadminshop",
    1: "User",
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getRole = (role) => roles[role] || "No role"
const SupportUser = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");
    const [chats, setChats] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [imageTargetOpenUrl, setImageTargetOpenUrl] = useState("");

    useEffect(() => {
        const fetchChats = async () => {
            try {
                setLoading(true);
                const data = await getSupportChats(id); // Передаем query параметр в запрос
                setChats(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchChats();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getUserInfo(id); // Передаем query параметр в запрос
                setData(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [id, refresh]); // Add refresh to the dependency array

    const handleBack = () => {
        navigate(-1)
    }

    const handleCloseConfirm = () => {
        setOpen(false);
    };

    const handleUnSupported = async () => {
        await unUnSupported(data?.id)
        setRefresh(prevState => !prevState);
        handleCloseConfirm()
        navigate(-1)
    }
    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
    }

    const handleSetOpenModal = (url) => {
        setImageTargetOpenUrl(url)
        setOpenModal(true)
    }

    const isMobile = useIsMobile();

    return (
        <div style={!isMobile? {padding: "10px 20px", width: "78%"} : {padding: "10px 20px", height: "100vh"}}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#1b5e3b"} marg={0}/>
                <h3 style={{textAlign: "center", position: "relative", top: "-30px"}}>{t("Support info")}</h3>
            </div>
            <div>
                {loading || !data ?
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box>
                    : <div>
                        <hr className={styles.lineTop}/>
                        <div className={styles.topBlockInfo}>
                            <span className={styles.topBlockInfoText}>{t("Main info")}</span>
                            <div className={styles.topBlockInfoBlock}>
                                <p className={styles.topText}>{t("Email")}:</p>
                                <p className={styles.downText}>{data.email}</p>
                            </div>
                            <div className={styles.topBlockInfoMainBlock}>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>ID:</p>
                                    <p className={styles.downText}>{data?.id ?? ""}</p>
                                </div>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>{t("Role")}:</p>
                                    <p className={styles.downText}>{getRole(data?.role)}</p>
                                </div>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>{t("User name")}: </p>
                                    <p className={styles.downText}>{data?.username ?? ""}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.lineMiddle}/>
                        <div className={styles.middleBlockInfoM}>
                            <span className={styles.topBlockInfoText}>{t("User content info")}</span>
                            <div className={styles.topBlockInfoMainBlockM}>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Active")}:</p>
                                    <p className={styles.downText}>{data?.is_active ? t("Yes") : t("No")}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Is verified")}:</p>
                                    <p className={styles.downText}>{data?.is_verified ? t("Yes") : t("No")}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Custom Role")}:</p>
                                    <p className={styles.downText}>{data?.client_status_role?.name || "None"}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Banned")}: </p>
                                    <p className={styles.downText}>{data?.black_list ? t("Yes") : t("No")}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.lineMiddle}/>
                        <div className={styles.middleBlockInfoM}>
                            <span className={styles.topBlockInfoText}>{t("User status info")}</span>
                            <div className={styles.middleBlockInfoD}>
                                <div className={styles.topBlockInfoMainContD}>
                                    <p className={styles.topText}>{t("Status")}:</p>
                                    <p className={styles.downText}>{data?.verification_info?.status_display ?? t("No results found")}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", marginTop: "15px"}}>
                            <div>
                                <p className={styles.docText}>{t("User photo")}</p>
                                <LazyLoad height={200} offset={100}>
                                    <img height={200}
                                         className={styles.imgShadow}
                                         src={process.env.REACT_APP_MEDIA_URL + data?.verification_info?.verification_photo_face}
                                         alt={t("User photo not found")}
                                         onClick={() => handleSetOpenModal(data?.verification_info?.verification_photo_face)}/>
                                </LazyLoad>
                            </div>
                            <div>
                                <p className={styles.docText}>{t("Documents photo")}</p>
                                <LazyLoad height={200} offset={100}>
                                    <img height={200}
                                         className={styles.imgShadow}
                                         src={process.env.REACT_APP_MEDIA_URL + data?.verification_info?.verification_pass_id}
                                         alt={t("Documents photo  not found")}
                                         onClick={() => handleSetOpenModal(data?.verification_info?.verification_pass_id)}/>
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.chats}>
                    {loading ?
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                            <CircularProgress/>
                        </Box> :
                        <>
                            {
                                chats.map(chat =>
                                    (
                                        <SupportChatOwner chat={chat}/>
                                    ))
                            }
                        </>
                    }
                </div>
                <div className={styles["button-container"]}>
                    <button className={styles["removeButton"]}
                            onClick={() => {
                                setOpen(true)
                                setIsOpenModel("2")
                            }}>
                        <span
                            className={styles["removeButton--element"]}>
                            {t("Demote")}
                        </span>
                    </button>
                </div>
            </div>
            {
                openModal && (
                    <div className={styles.madalMedia}>
                        <div className={styles.closeIcon} onClick={() => setOpenModal(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                <path
                                    fill={"black"}
                                    d="M5.30963553,18.6903645 C4.8990815,18.2798104 4.90303703,17.6102153 5.30742781,17.2058245 L10.5132523,12 L5.30742781,6.79417547 C4.89809307,6.38484073 4.89621919,5.72305186 5.30963553,5.30963553 C5.72018956,4.8990815 6.38978469,4.90303703 6.79417547,5.30742781 L12,10.5132523 L17.2058245,5.30742781 C17.6151593,4.89809307 18.2769481,4.89621919 18.6903645,5.30963553 C19.1009185,5.72018956 19.096963,6.38978469 18.6925722,6.79417547 L13.4867477,12 L18.6925722,17.2058245 C19.1019069,17.6151593 19.1037808,18.2769481 18.6903645,18.6903645 C18.2798104,19.1009185 17.6102153,19.096963 17.2058245,18.6925722 L12,13.4867477 L6.79417547,18.6925722 C6.38484073,19.1019069 5.72305186,19.1037808 5.30963553,18.6903645 Z"></path>
                            </svg>
                        </div>
                        <div className={styles["image-wrapper"]}>
                            <img className={styles["image-order"]} loading="lazy"
                                 src={process.env.REACT_APP_MEDIA_URL + imageTargetOpenUrl || unknownImage}
                                 alt="ProductImage"/>
                        </div>
                    </div>
                )
            }
            <StyledDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {isOpenModel === "1" ? t("Are you sure you want to verify the user?") : t("Are you sure you want to delete verification?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleUnSupported}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default SupportUser;