import React, {useEffect, useState} from 'react';
import {getAllUser} from "../../api/getContentsApi/getAllUser";
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    styled
} from "@mui/material";

import {t} from "i18next";
import debounce from 'lodash.debounce';
import {Link, useMatch} from "react-router-dom";
import {StyledSearchInput} from "../StyledSearchInput/StyledSearchInput";

const AllUsersTableBlackList = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getAllUser(searchQuery); // Передаем query параметр в запрос
                setUsers(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        const debouncedFetchUsers = debounce(fetchUsers, 700);
        debouncedFetchUsers();

        return () => {
            debouncedFetchUsers.cancel();
        };
    }, [searchQuery]);


    // Пагинация данных
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({

        padding: '7px',
    }));

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <StyledSearchInput
                    variant="outlined"
                    label={t("Search users")}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{width: '100%', marginBottom: '10px','& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                            top: "10px",
                            color: '#2196f3',
                            fontSize: 14,
                        },}}
                />

            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                <CircularProgress/>
            </Box>:
            <>
                <TableContainer sx={{maxHeight: "45vh"}}>
                    <Table  aria-label="users table" >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{width: 100}}>{t("User name")}</StyledTableCell>
                                <StyledTableCell sx={{width: 80}}>{t("Banned")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                <TableRow key={user.id}>
                                    <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                        <Link to={`/owner/black-list/user/${user.id}`}>
                                            {user.username}
                                        </Link>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                        {user.black_list
                                            ? t('Banned') : t('Not banned')}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5,10, 25, 50, 100]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>}

        </Paper>
    );
};

export default AllUsersTableBlackList;
