import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getUserInfo} from "../../api/getContentsApi/getUserInfo";
import IconBack from "../Icons/IconBack";
import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    styled
} from "@mui/material";
import {t} from "i18next";

import styles from "./VerificateModerator.module.scss";

import {verifitateModerator} from "../../api/addContent/verifitateModerator";
import useIsMobile from "../../helpers/useIsMobile";

const roles = {
    5: "Support",
    4: "Owner",
    3: "Courier",
    2: "Superadminshop",
    1: "User",
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getRole = (role) => roles[role] || "No role"
const VerificateModerator = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getUserInfo(id);
                setData(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [id, refresh]); // Add refresh to the dependency array

    const handleBack = () => {
        navigate(-1)
    }

    const handleCloseConfirm = () => {
        setOpen(false);
    };

    const handleVerifiUser = async () => {
        await verifitateModerator(data?.id)
        setRefresh(prevState => !prevState);
        handleCloseConfirm()
        navigate(-1)
    }

    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
    }

    const isMobile = useIsMobile();

    return (
        <div style={isMobile ? {padding: "10px 20px"} : {padding: "10px 20px", width: "78%"}}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#1b5e3b"} marg={0}/>
                <h3 style={{textAlign: "center", position: "relative", top: "-30px"}}>{t("Moderator info")}</h3>
            </div>
            <div>
                {loading || !data ?
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box>
                    : <div>
                        <hr className={styles.lineTop}/>
                        <div className={styles.topBlockInfo}>
                            <span className={styles.topBlockInfoText}>{t("Main info")}</span>
                            <div className={styles.topBlockInfoBlock}>
                                <p className={styles.topText}>{t("Email")}:</p>
                                <p className={styles.downText}>{data.email}</p>
                            </div>
                            <div className={styles.topBlockInfoMainBlock}>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>ID:</p>
                                    <p className={styles.downText}>{data?.id ?? ""}</p>
                                </div>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>{t("Role")}:</p>
                                    <p className={styles.downText}>{getRole(data?.role)}</p>
                                </div>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>{t("User name")}: </p>
                                    <p className={styles.downText}>{data?.username ?? ""}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.lineMiddle}/>
                        <div className={styles.middleBlockInfoM}>
                            <span className={styles.topBlockInfoText}>{t("User content info")}</span>
                            <div className={styles.topBlockInfoMainBlockM}>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Active")}:</p>
                                    <p className={styles.downText}>{data?.is_active ? t("Yes") : t("No")}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Is verified")}:</p>
                                    <p className={styles.downText}>{data?.is_verified ? t("Yes") : t("No")}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Custom Role")}:</p>
                                    <p className={styles.downText}>{data?.client_status_role?.name || "None"}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Banned")}: </p>
                                    <p className={styles.downText}>{data?.black_list ? t("Yes") : t("No")}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.lineMiddle}/>
                        <div className={styles.middleBlockInfoM}>
                            <span className={styles.topBlockInfoText}>{t("User status info")}</span>
                            <div className={styles.middleBlockInfoD}>
                                <div className={styles.topBlockInfoMainContD}>
                                    <p className={styles.topText}>{t("Status")}:</p>
                                    <p className={styles.downText}>{data?.verification_info?.status_display ?? t("No results found")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles["button-container"]}>
                    <button className={styles["add-button"]}
                            onClick={() => {
                                {
                                    setOpen(true)
                                    setIsOpenModel("1")
                                }
                            }}>
                    <span
                        className={styles["add-button--element"]}>{t("Verify moderator")}
                    </span>
                    </button>

                </div>
            </div>
            <StyledDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {isOpenModel === "1" ? t("Are you sure you want to verify the moderator?") : t("Are you sure you want to delete verification?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleVerifiUser}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default VerificateModerator;