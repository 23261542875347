import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import styles from "./RoleSettingItem.module.scss";
import IconBack from "../../components/Icons/IconBack";
import {
    Button, Card, CardContent, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled
} from "@mui/material";
import EditIcon from "../../components/Icons/EditIcon";
import useIsMobile from "../../helpers/useIsMobile";
import { HexColorPicker } from "react-colorful";
import { addOwnerRole } from "../../api/addContent/addOwnerRole";
import { useNavigate, useParams } from "react-router-dom";
import { getOwnerRoleInfo } from "../../api/getContentsApi/getOwnerRoleInfo";
import { deleteOwnerRoleInfo } from "../../api/addContent/deleteOwnerRoleInfo";
import { updateOwnerRoleInfo } from "../../api/addContent/updateOwnerRoleInfo";
const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 20px !important;
    }
`;

const RoleSettingItem = () => {
    const { id } = useParams();
    const [color, setColor] = useState("#ff0000");
    const [roleId, setRoleId] = useState(null);
    const [isOn, setIsOn] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [openDialog, setOpenDialog] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const data = await getOwnerRoleInfo(id);
                setRoleName(data.name);
                setRoleId(data.id);
                setColor(data.highlight_color);
                setIsOn(data.verification_authentication);
            }
        };
        fetchData();
    }, [roleId]);

    const handleSubmitRole = async () => {
        await addOwnerRole({
            "name": roleName,
            "highlight_color": color,
            "verification_authentication": isOn
        });
        navigate(-1);
    };

    const handleUpdateRole = async () => {
        await updateOwnerRoleInfo({
            "name": roleName,
            "highlight_color": color,
            "verification_authentication": isOn
        }, id);
        navigate(-1);
    };

    const handleDeleteRole = async () => {
        await deleteOwnerRoleInfo(id);
        navigate(-1);
    };

    const handleConfirmAction = (action) => {
        setOpenDialog(action);
    };

    const handleCloseDialog = () => {
        setOpenDialog(null);
    };

    const handleConfirm = () => {
        if (openDialog === 'edit') {
            handleUpdateRole();
        } else if (openDialog === 'delete') {
            handleDeleteRole();
        }
        handleCloseDialog();
    };

    const isMobile = useIsMobile();

    return (
        <div style={isMobile ? { padding: "10px" } : { padding: "10px", width: "78%" }}>
            <div className={styles.mainHeaderBlock}>
                <IconBack size={20} pure={true} fill={"#1b5e3b"} marg={0} />
                <h3 className={styles.titleMiddle}>{t("Role")}</h3>
            </div>
            <div className={styles.mainBlock}>
                <h4>{t('New')}</h4>
                <div className={styles.form}>
                    <div style={{ position: "relative" }} className={styles.mainBlockOne}>
                        <label htmlFor="categoryName" className={styles.mainBlockLabel}>
                            {t("Role name")}
                        </label>
                        <input
                            type="text"
                            id="rolename"
                            name="roleName"
                            className={styles.mainBlockDownInput}
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                        />
                        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                            <EditIcon />
                        </div>
                    </div>

                    <div className={styles.mainBlockDownImage}>
                        <label htmlFor="image" className={styles.mainBlockLabel}>
                            {t("Color for role")}
                        </label>
                        <div className={styles.selectColor}>
                            <Card className="w-64 p-4 shadow-lg">
                                <CardContent className="flex flex-col items-center gap-4">
                                    <HexColorPicker color={color} onChange={setColor} />
                                    <div
                                        className="w-16 h-16 rounded-full border border-gray-300"
                                        style={{ backgroundColor: color }}
                                    ></div>
                                    <Button>Выбрать цвет</Button>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    <div className={styles.verificationBlock}>
                        <span className={styles.titleVer}>{t("Verification")}</span>
                        <Switch className={styles.switchStyle} onChange={() => setIsOn(!isOn)} checked={isOn} />
                    </div>
                    <div className={styles["button-container"]}>
                        {!id ? (
                            <button type="submit" className={styles["add-button"]} onClick={handleSubmitRole}>
                                {t("Save")}
                            </button>
                        ) : (
                            <div className={styles.btnBlockDown}>
                                <button type="button" className={styles.editBtn} onClick={() => handleConfirmAction('edit')}>
                                    {t("Edit role")}
                                </button>
                                <button type="button" className={styles.deleteBtn} onClick={() => handleConfirmAction('delete')}>
                                    {t("Delete role")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <StyledDialog open={openDialog !== null} onClose={handleCloseDialog}>
                <DialogTitle>{t("Wa")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {openDialog === 'edit' ? t("Are you sure you want to edit this role?") : t("Are you sure you want to delete this role?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        {t("Confirm")}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default RoleSettingItem;
