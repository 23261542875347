import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import IconBack from "../Icons/IconBack";
import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    styled, TextField
} from "@mui/material";
import {t} from "i18next";

import styles from "./EditModerator.module.scss";

import {unVerifitateModerator} from "../../api/addContent/unVerifitateModerator";
import {getModeratorInfo} from "../../api/getContentsApi/getModeratorInfo";

import EditIcon from "../Icons/EditIcon";
import {updateModeratorInfo} from "../../api/addContent/updateModeratorInfo";
import useIsMobile from "../../helpers/useIsMobile";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;
const roles = {
    5: "Support",
    4: "Owner",
    3: "Courier",
    2: "Superadminshop",
    1: "User",
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getRole = (role) => roles[role] || "No role"
const EditModerator = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");
    const [editField, setEditField] = useState("");
    const [newValue, setNewValue] = useState("");
    const [errorMessages, setErrorMessages] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getModeratorInfo(id);
                setData(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [id, refresh]); // Add refresh to the dependency array

    const handleBack = () => {
        navigate(-1)
    }

    const handleCloseConfirm = () => {
        setOpen(false);
    };

    const handleUnverifiUser = async () => {
        await unVerifitateModerator(data?.id)
        setRefresh(prevState => !prevState);
        handleCloseConfirm()
        navigate(-1)
        setEditField("");
        setNewValue("");
    }
    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
        setEditField("");
        setNewValue("");
        setErrorMessages([]);
    }

    const openEditModal = (field) => {
        setEditField(field);
        setNewValue(data.platform_settings[field] || "");
    };

    const handleSave = async () => {
        try {
            const updatedData = {  [editField]: newValue  };

            await updateModeratorInfo(id, updatedData);
            setRefresh((prev) => !prev);
            handleClose();
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessages(error.response.data.errors || ["An unexpected error occurred."]);
            } else {
                setErrorMessages(["An unexpected error occurred."]);
            }
        } finally {

        }
    };

    const isMobile = useIsMobile();

    return (
        <div style={isMobile ? {padding: "10px 20px", overflow: "auto"} : {padding: "10px 20px", width: "78%"}}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#1b5e3b"} marg={0}/>
                <h3 style={{textAlign: "center", position: "relative", top: "-30px"}}>{t("Moderator info")}</h3>
            </div>
            <div>
                {loading || !data ?
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box>
                    : <div>
                        <hr className={styles.lineTop}/>
                        <div className={styles.topBlockInfo}>
                            <span className={styles.topBlockInfoText}>{t("Main info")}</span>
                            <div className={styles.topBlockInfoBlock}>
                                <p className={styles.topText}>{t("Email")}:</p>
                                <p className={styles.downText}>{data.email}</p>
                            </div>
                            <div className={styles.topBlockInfoMainBlock}>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>ID:</p>
                                    <p className={styles.downText}>{data?.id ?? ""}</p>
                                </div>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>{t("Role")}:</p>
                                    <p className={styles.downText}>{getRole(data?.role)}</p>
                                </div>
                                <div className={styles.topBlockInfoMainCont}>
                                    <p className={styles.topText}>{t("User name")}: </p>
                                    <p className={styles.downText}>{data?.username ?? ""}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.lineMiddle}/>
                        <div className={styles.middleBlockInfoM}>
                            <span className={styles.topBlockInfoText}>{t("User content info")}</span>
                            <div className={styles.topBlockInfoMainBlockM}>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Active")}:</p>
                                    <p className={styles.downText}>{data?.is_active ? t("Yes") : t("No")}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Is verified")}:</p>
                                    <p className={styles.downText}>{data?.is_verified ? t("Yes") : t("No")}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Custom Role")}:</p>
                                    <p className={styles.downText}>{data?.client_status_role?.name || "None"}</p>
                                </div>
                                <div className={styles.topBlockInfoMainContM}>
                                    <p className={styles.topText}>{t("Banned")}: </p>
                                    <p className={styles.downText}>{data?.black_list ? t("Yes") : t("No")}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.lineMiddle}/>
                        <div className={styles.middleBlockInfoM}>
                            <span className={styles.topBlockInfoText}>{t("User status info")}</span>
                            <div className={styles.middleBlockInfoD}>
                                <div className={styles.topBlockInfoMainContD}>
                                    <p className={styles.topText}>{t("Status")}:</p>
                                    <p className={styles.downText}>{data?.verification_info?.status_display ?? t("No results found")}</p>
                                </div>
                            </div>
                        </div>
                        <div style={isMobile ? {
                            border: "1px solid black",
                            padding: "10px",
                            marginTop: "10px",
                            borderRadius: "10px"
                        } : {}}>
                            <p>{t("Subscription cost")}: {data.platform_settings.seller_deposit} $
                                <Button onClick={() => openEditModal("seller_deposit")}><EditIcon/></Button>
                            </p>
                            <p>{t("Subscription expiration date")}: {data.platform_settings.subscription_expiry}
                                <Button onClick={() => openEditModal("subscription_expiry")}><EditIcon/></Button>
                            </p>
                            <p>{t("Percentage of purchases")}: {data.platform_settings.transaction_commission} %
                                <Button onClick={() => openEditModal("transaction_commission")}><EditIcon/></Button>
                            </p>
                        </div>
                    </div>
                }

                <div className={styles["button-container"]}>
                    <button className={styles["removeButton"]}
                            onClick={() => {
                                setOpen(true)
                                setIsOpenModel("2")
                            }}>
                        <span
                            className={styles["removeButton--element"]}>
                            {t("Unverified moderator")}
                        </span>

                    </button>
                </div>

            </div>
            <StyledDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("Are you sure you want to demote this moderator?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleUnverifiUser}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
            <StyledDialog open={!!editField} onClose={handleClose}>
                <DialogTitle>{t("Edit Field")}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={t("New Value")}
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    {errorMessages && (
                        <div style={{color: "red", marginTop: "10px"}}>
                            {Object.entries(errorMessages).map(([key, value]) => (
                                <div key={key}>{value}</div>
                            ))}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <Button onClick={handleSave}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>

        </div>
    );
};

export default EditModerator;