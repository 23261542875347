import api from "../api";

export const getSearchOwnerTags = async () => {
    try {
        const users = await api.get(`/owner_site/shops/search/o/tags/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}