import * as React from 'react';
import styles from './NewSliderProduct.module.scss'
import {useRef} from "react";
import NewShopSliderCard from "./NewShopSliderCard/NewShopSliderCard";

function NewSliderProduct({products}) {
    const sliderContainerRef = useRef(null);

    return (
        <div className={styles.sliderBar}>
            <div className={styles.sliderBarBlock}>
                <div className={styles.sliderConteiner} ref={sliderContainerRef}>
                    {
                        products.map((product) => (
                            <NewShopSliderCard product={product} key={product.ProductID} countP={products.length}/>
                        ))
                    }
                    <div className={styles.fakeElem}></div>
                </div>
            </div>
        </div>
    );
}
export default NewSliderProduct;