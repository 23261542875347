import React, {useEffect, useState} from 'react';
import styles from "./AccountMobile.module.scss"
import rootStore from "../../../store/RootStore";
import WithAuthUser from "../../../helpers/WithAuthUser";
import {useNavigate} from "react-router-dom";
import IconBack from "../../../components/Icons/IconBack";
import {getAddress, getUser} from "../../../helpers";
import ModalLocation from "../../../components/ModalLocation/ModalLocation";
import {useTheme} from "../../../helpers/ThemeContext";
import {observer} from "mobx-react";
import {t} from "i18next";
import ModalCurrency from "../../../components/ModalCurrency/ModalCurrency";
import {resetPassword} from "../../../api/addContent/resetPassword";
import ModalReset from "../ModalReset/ModalReset";

const AccountMobile = () => {
    const { getProfile, userData, changePhote } = rootStore.userStore;
    const { loadCurrentAddress } = rootStore.addressStore;
    const navigate = useNavigate();
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const [openCurrency, setOpenCurrency] = useState(false);
    const { themeMode } = useTheme()
    const [isOpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {
        getProfile()
    }, []);
    useEffect(() => {
        loadCurrentAddress(userData.profile.country)
    }, [userData]);

    const userLoc = JSON.parse(getUser())
    const avatar = userLoc.profile.photo;

    const handleChangeAvatar = async(e) => {
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append('photo', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await changePhote(formData, config)
    }

    const handleGoToMobile = () => {
        navigate('/discovery/me/change-mobile')
    }

    const handleClickOpenCountrys = () => {
        setIsOpenCountry(!isOpenCountry)
    }

    const handleClickCurrency = () => {
        setOpenCurrency(!openCurrency)
    }
    const address = JSON.parse(getAddress())
    const addressLabel = address?.label ?? t("Your address is not set");

    const goBack = () => {
        navigate('/discovery/me', { replace: true });
    };
    const handleOpenModal = () => {
        setIsOpenModal(true)
    }
    const handleSubmitPassword = async (data) => {
        try {
            await resetPassword(data);
            setIsOpenModal(false);
        } catch (error) {
            console.error("Password reset failed:", error);
        }
    };
    return (
        <WithAuthUser>
            {openCurrency ? <></> : <div className={styles.goBackButton} onClick={goBack}>
                <IconBack size={20} pure={true} fill={"#1b5e3b"}/>
            </div>}
            {isOpenModal && (
                <ModalReset
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    onSubmit={(data) => handleSubmitPassword(data)}
                />
            )}
            <div className={styles.profileCard}>
                <h2 className={styles.mainTitle}>{t("Account")}</h2>
                <div className={styles.downConteiner}>
                    <div className={styles.infoBlock}>
                        <ModalLocation>
                            <div className={styles.infoRowLvl} onClick={() => handleClickOpenCountrys()}>
                                <span className={styles.cardText}>{t("Your Address")}</span>
                                <div className={styles.tokensMain}>
                                    <span className={styles.cardTextMain}>{addressLabel}</span>
                                    <div className={styles.IconNextBlock}>
                                        <svg viewBox="0 0 20 20" className={styles.IconNext}>
                                            <path
                                                d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </ModalLocation>
                        <div className={styles.infoRowLvl}>
                            <span className={styles.cardText}>{t("Profile Picture")}</span>
                            <div className={styles.tokensMain}>
                                <div className={styles.imageWrapper}>
                                    <div className={styles.profileImage}>
                                        <input type="file" name="file" id="file" className={styles.uploadInput}
                                               accept="image/*" value=""
                                               onChange={(e) => handleChangeAvatar(e)}/>
                                        {themeMode === "dark" && <img
                                            src={`${avatar ? process.env.REACT_APP_MEDIA_URL + avatar : "https://consumer-user.wolt.com/s/_wt8B-lIufWCfSysvGahB6Q4vravvbuUB8kWumSIjK0/CH/g"}`}
                                            alt="Profile"/>}
                                    </div>
                                </div>
                                <div className={styles.IconNextBlock}>
                                    <svg viewBox="0 0 20 20" className={styles.IconNext}>
                                        <path
                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className={styles.infoRowLvl}>
                            <span className={styles.cardText}>Email</span>
                            <div className={styles.tokensMain}>
                                <span className={styles.cardTextMain}>{userData.profile.username}</span>
                            </div>
                        </div>
                        <div className={styles.infoRowLvl} onClick={() => handleGoToMobile()}>
                            <span className={styles.cardText}>{t("Mobile number")}</span>
                            <div className={styles.tokensMain}>
                                <span className={styles.cardTextMain}>{userData.profile.phone_number}</span>
                                <div className={styles.IconNextBlock}>
                                    <svg viewBox="0 0 20 20" className={styles.IconNext}>
                                        <path
                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className={styles.infoRowLvl}>
                            <span className={styles.cardText}>{t("Name")}</span>
                            <div className={styles.tokensMain}>
                                <span className={styles.cardTextMain}>{userData.profile.username}</span>
                            </div>
                        </div>
                        <div className={styles.infoRowLvl}>
                            <span className={styles.cardText}>{t("Send receipts to email")}</span>
                            <div className={styles.tokensMain}>
                                <section className={styles.selectRow}>
                                    <div className={styles.contentRowBlock}>
                                        <label className={styles.switch}>
                                            <input type="checkbox"/>
                                            <span className={`${styles.slider} ${styles.round}`}></span>
                                        </label>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className={styles.infoRowLvl} onClick={handleClickCurrency}>
                            <span className={styles.cardText}>{t("Selected currency")}</span>
                            <div className={styles.tokensMain}>
                                <span className={styles.cardTextMain}>{userData.profile.currency_type}</span>
                                <div className={styles.IconNextBlock}>
                                    <svg viewBox="0 0 20 20" className={styles.IconNext}>
                                        <path
                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className={styles.infoRowLvl} onClick={() => handleOpenModal()}>
                            <span className={styles.cardText}>{t("Reset password")}</span>
                            <div className={styles.tokensMain}>
                                <div className={styles.IconNextBlock}>
                                    <svg viewBox="0 0 20 20" className={styles.IconNext}>
                                        <path
                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalCurrency isOpen={openCurrency} setIsOpen={setOpenCurrency}/>
            </div>
        </WithAuthUser>
    );
};

export default observer(AccountMobile);