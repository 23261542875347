import React, {useEffect, useState} from 'react';
import styles from "./VerificationDocument.module.scss"
import WithAuthUser from "../../helpers/WithAuthUser";
import {useNavigate} from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";
import { t } from "i18next";
import VerificationScanner from "./VerificationScanner";
import rootStore from "../../store/RootStore";

const VerificationDocument = () => {
    const navigate = useNavigate();
    const [isScanning, setIsScanning] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const {sendVerificationDoc, getProfile} = rootStore.userStore;

    useEffect(() => {
        return () => {
            if (capturedImage) {
                URL.revokeObjectURL(capturedImage);
            }
        };
    }, [capturedImage]);

    const handleVerification = () => {
        setIsScanning(true);
    };

    const handleCapture = (image) => {
        console.log("Документ сохранен:", image);
        setCapturedImage(image)
        setIsScanning(false);
    };

    const handleSendVerifToServ = async () => {
        if (capturedImage) {
            const forma= new FormData();
            forma.append("verification_pass_id", capturedImage);
            await sendVerificationDoc(forma)
            setTimeout(async () => {
                await getProfile()
            },500)
            setTimeout(() => {
                navigate({pathname:"../verification3",relative: true})
            },1000)
        }
    }

    return (
        <WithAuthUser>
            {isScanning ? (
                <VerificationScanner onCapture={handleCapture} onClose={() => setIsScanning(false)} />
            ) : (
                <div>
                    {/* Верхняя панель */}
                    <div style={{
                        position: "fixed",
                        width: "32px",
                        height: "32px",
                        zIndex: 999999,
                        top: "1%",
                        left: "3%",
                    }}>
                        <IconBack onClick={() => navigate("/discovery/me", { replace: true })} size={20} pure={true} fill={"#1b5e3b"} />
                        <h2 className={styles.mainTitle}>{t("Verification")}</h2>
                    </div>

                    {/* Карточка профиля */}
                    <div className={styles.profileCard}>
                        <div className={styles.downConteiner}>
                            <div className={styles.infoBlock}>
                                <div className={styles.blockTextContent}>
                                    <span className={styles.titleUp}>{t("Document verification")}</span>
                                    <span className={styles.titleDown}>
                                        {t("The image must be clear and fit strictly within the frames allocated on the screen!")}
                                    </span>
                                    {capturedImage ? (
                                        <img className={styles.imgDoc} src={URL.createObjectURL(capturedImage)} alt="Scanned Document" />
                                    ) : (
                                        <img
                                            className={styles.imgDoc}
                                            src="https://access-techniques.com/wp-content/uploads/2023/09/Training-500x500.webp"
                                            alt="document"
                                        />
                                    )}
                                    <span className={styles.titleDown}>
                                        {t("Make sure the image is clearly visible for reading.")}
                                    </span>
                                </div>
                                <button className={styles.activeBtn} onClick={handleVerification}>
                                    {!capturedImage ? t("Verificate") : t("Restart Verificate")}
                                </button>
                                {
                                    capturedImage &&
                                    (
                                        <button className={styles.activeBtn2} onClick={handleSendVerifToServ}>
                                            {t("Send document")}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </WithAuthUser>
    );
};

export default VerificationDocument;