import api from "../api";

export const deleteUserSearchHistory = async () => {
  try {
    const apiResponse = await api
      .delete(`user-history/clear/ `)
      .then((response) => response);
    return apiResponse;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
