import api from "../api";

export const getAllBannedUser = async () => {
    try {
        const users = await api.get(`/owner_site/verifications/users/blacklisted/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}