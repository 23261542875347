import React from 'react';
import styles from './CloseVerification.module.scss';

const CloseVerification = ({size="24px", fill="black", setOpenVerificatModal}) => {

    const handleClick = () => {
        setOpenVerificatModal(false);  // Закрываем модальное окно
    };

    return (
        <button onClick={handleClick} className={styles.IconBack} style={{ zIndex: 999999999 }}>
            <div className={styles["IconBack__icon"]}>
                <svg viewBox="0 0 24 24" width={size} height={size} color="black" className={styles.mainSvg}>
                    <path
                        className={styles.mainSvg}
                        fill={fill}
                        d="M22.533 10.526H5.422a.251.251 0 01-.165-.438l4.637-3.6a1.44 1.44 0 00-1.9-2.162L.813 10.165a2.4 2.4 0 000 3.6l7.179 5.837a1.44 1.44 0 001.9-2.161l-4.637-3.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.88v.003z"
                    ></path>
                </svg>
            </div>
        </button>
    );
};

export default CloseVerification;
