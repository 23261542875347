import styles from './NotFound.module.scss'
import { ReactComponent as MySvg } from './404.svg';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const NotFound = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleGoWolt = () => {
        navigate('/discovery')
    }

    return (
        <div className={styles.blockNotFound}>
                <main id="mainContent" tabIndex="-1" className={styles.m1m6ijyu}>
                    <div className={styles.ah0U76}>
                        <div className={styles.c1nhws76}>
                            <div data-nosnippet="true" className={styles.rpkah7y}>
                                <div className={styles.i2vnumb}>
                                    <div className={styles.ab07vvg}>
                                        <div className={styles.Illustration__image} aria-hidden="true">
                                            <MySvg />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.s1wxwy35}>
                                    <span className={styles.tseoo83}>Something unexpected happened</span>
                                </div>
                                <div className={styles.s1wxwy35}>
                                    <div className={styles.b1gmi0cx}>
                                        Unfortunately, we couldn't find the content you were looking for. Sorry for the hassle!
                                    </div>
                                </div>
                                <div className={styles.s1wxwy35}>
                                    <button
                                        aria-busy="false"
                                        className={styles.cbc_Button_rootClass_0259f}
                                        data-size="medium"
                                        data-variant="secondary"
                                        type="button"
                                        data-test-id="ErrorViewButton"
                                        onClick={() => handleGoWolt()}
                                    >
                                        <div className={styles.cbc_Button_bgClass_0259f}></div>
                                        <div className={styles.cbc_Button_spinnerContainer_0259f}></div>
                                        <div className={styles.cbc_Button_content_0259f}>Back to Wolt</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
        </div>
    );
};

export default NotFound;