import React from 'react';
import styles from './IconForward.module.scss'

const IconForward = ({color="#1b5e3b"}) => {
    return (
        <div style={{width:"20px"}} className={styles.info}>
            <svg viewBox="0 0 24 24" fill={color} className={styles.infoImg}>
            width: 0.875rem;
            transform: scaleX(1);>
                <path
                    d="M19.5 11.972c0-.662-.283-1.292-.778-1.73L7.592.433a1.84 1.84 0 00-2.568.195 1.85 1.85 0 00.134 2.578l9.732 8.579a.25.25 0 010 .376l-9.732 8.578a1.849 1.849 0 00.609 3.17 1.84 1.84 0 001.825-.397l11.126-9.807a2.312 2.312 0 00.782-1.732z"></path>
            </svg>
        </div>
    );
};

export default IconForward;