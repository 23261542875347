import api from "../api";

export const getOwnerShopDetails = async (id) => {
    try {
        if(id) {
            const shops = await api.get(`/owner_site/shops/shop/${id}/details/`);
            return shops.data;
        }
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}