import React from 'react';
import styles from "./StoresCard.module.scss"
import Tags from "../Tags/Tags";
import {Link} from "react-router-dom";
import {getCurrencySymbol} from "../../../helpers/getCurrency";
import {Skeleton} from "@mui/material";

const StoresCard = ({ shop }) => {
    const symbol = getCurrencySymbol();
    const {
        ShopID,
        opening_times,
        shop_images,
        ShopName,
        Description,
        Address,
        Contact_description,
        Base_delivery_fee,
        price_in_user_currency_delivery,
        Small_order_surcharge_limit,
        Long_delivery_surcharge_limit,
        Estimated_delivery_time,
        SellerID,
        CategoryID,
        Average_rating,
        Average_price
    } = shop;

    const imageUrl = process.env.REACT_APP_MEDIA_URL + shop_images[0]?.ImageUrl;
    const imageAlt = shop_images[0]?.AltText;

    const [imageLoaded, setImageLoaded] = React.useState(false);  // Хук для отслеживания состояния загрузки изображения

    const handleImageLoad = () => {
        setImageLoaded(true);  // Изображение загружено
    };

    return (
        <div className={styles.mainConteiner}>
            <div>
                <div className={styles.headerBlcok}>
                    <div className={styles.imgBlock}>
                        <div className={styles.imgConteiner}>
                            {/* Skeleton, который будет показываться, пока изображение не загружено */}
                            {!imageLoaded && <Skeleton variant="rectangular" width="100%" height={180} />}

                            <img
                                loading="eager"
                                className={styles.img}
                                src={imageUrl}
                                alt={imageAlt}
                                draggable={true}
                                onLoad={handleImageLoad}  // Обработчик события, когда изображение загружено
                                style={{ display: imageLoaded ? 'block' : 'none' }}  // Показываем изображение только после загрузки
                            />
                        </div>
                    </div>
                    <div className={styles.headerTextBlock}>
                        <Tags text={"24/7"} />
                    </div>
                </div>
                <div className={styles.mainBlock}>
                    <div className={styles.leftConteiner}>
                        <div className={styles.upBlock}>
                            <Link state={{ shopId: ShopID }} to={`/stores/${ShopID}`} className={styles.linkConteiner}>
                                <h3 className={styles.linkTitle}>{ShopName}</h3>
                            </Link>
                        </div>
                        <div className={styles.downBlock}>
                            <p className={styles.description}>{Description}</p>
                        </div>
                    </div>
                    <div className={styles.rightConteiner}>
                        <div className={styles.rightBoxCont}>
                            <div className={styles.rightBoxContUp}>{Math.floor(+Small_order_surcharge_limit)} - {Math.floor(+Long_delivery_surcharge_limit)}</div>
                            <div className={styles.rightBoxContUp} style={{ fontSize: "0.75rem" }}> min</div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerBlock}>
                    <div className={styles.footerContentBlock}>
                        <span className={styles.footerTextBlock}>
                            <svg viewBox="0 0 24 24" className={styles.textImg}><path
                                d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"></path></svg>
                            <span className={styles.textCost}>{price_in_user_currency_delivery || Base_delivery_fee} {symbol}</span>
                        </span>
                        <span className={styles.footerTextBlockN}>
                            <span className={styles.footerTextBlockNContent}>
                                <span style={{ fontSize: "0.9rem", color: "var(--grey-text-color)", fontWeight: "400" }}>
                                    {symbol.repeat(Average_price)}
                                </span>
                                <span className={styles.baksImg}>{symbol.repeat(4 - Average_price)}</span>
                            </span>
                        </span>
                        <span className={styles.footerTextBlockR}>
                            <svg viewBox="0 0 24 24" className={styles.smileImg}><path
                                d="M12 0c6.6 0 12 5.4 12 12s-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0zm0 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.7 12.2c.4.3.6.8.3 1.3-1.2 1.9-2.8 3-5 3.1-2.2-.1-3.8-1-4.8-2.9-.2-.5-.2-.9.1-1.3.2-.3.6-.4 1-.3.4.1.5.4.7.7.7 1.3 1.9 1.9 3.3 1.7 1.2-.1 2-.7 2.8-1.6.1-.2.2-.4.4-.5.2-.4.7-.5 1.2-.2zM16 7c1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9c-1 0-1.9-.8-1.9-1.9 0-1.1.8-1.9 1.9-1.9zM8 7c1 0 1.9.8 1.9 1.9s-.8 1.8-1.9 1.8c-1.1 0-1.9-.8-1.9-1.9C6.1 7.8 7 7 8 7z"
                                fill="var(--grey-text-color)"></path></svg>
                            <span className={styles.points}>{Average_rating}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoresCard;