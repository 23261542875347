import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getOwnerCategoryInfo } from "../../api/getContentsApi/getOwnerCategoryInfo";
import styles from "./CategorySettingItem.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import IconImage from "../../components/Icons/IconImage";
import * as Yup from "yup";
import IconBack from "../../components/Icons/IconBack";
import { addOwnerCategory } from "../../api/addContent/addOwnerCategory";
import { deleteOwnerCategory } from "../../api/addContent/deleteOwnerCategory";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    styled
} from "@mui/material";
import EditIcon from "../../components/Icons/EditIcon";
import useIsMobile from "../../helpers/useIsMobile";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 20px !important;
    }
`;

const CategorySettingItem = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [categoryName, setCategoryName] = useState('');
    const [image, setImage] = useState(null);
    const [categoryId, setCategoryId] = useState(id || null);
    const [imagePreview, setImagePreview] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
    const [formValues, setFormValues] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const data = await getOwnerCategoryInfo(id);
                setCategoryName(data.CategoryName);
                setCategoryId(data.CategoryID);
                setImage(data.category_images[0].ImageUrl);
            }
        };
        fetchData();
    }, [id]);

    const validationSchema = Yup.object().shape({
        categoryName: Yup.lazy(() =>
            id
                ? Yup.string().nullable()
                : Yup.string().required(t("Required field category name"))
        ),
        image: Yup.lazy(() =>
            id
                ? Yup.mixed().nullable()
                : Yup.mixed()
                    .required(t("Please select an image"))
                    .test('fileSize', t("File size is too large, maximum 10MB allowed"), (value) => {
                        return !value || value.size <= 10485760;
                    })
                    .test('fileType', t("File must be an image"), (value) => {
                        return !value || value.type.startsWith('image/');
                    })
        ),
    });

    const handleDelete = async () => {
        if(id) {
            await deleteOwnerCategory(id);
            navigate("/owner/shops", { replace: true, state: { selectedTab: 2 } });
            setOpenDelete(false);
        }
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    }

    const handleCloseSaveConfirm = () => {
        setOpenSaveConfirm(false);
    }

    const initialValues = {
        categoryName: categoryName || '',
        image: null,
    };

    const onSubmit = (values) => {
        setFormValues(values);
        setOpenSaveConfirm(true);
    };

    const handleConfirmSubmit = async () => {
        setOpenSaveConfirm(false);
        if (formValues) {
            const formData = new FormData();
            if (formValues.image) {
                formData.append('ImageUrl', formValues.image);
                formData.append('AltText', formValues.image.name);
            }
            if (formValues.categoryName) {
                formData.append('CategoryName', formValues.categoryName);
            }

            await addOwnerCategory(formData, id);
            navigate("/owner/shops", { replace: true, state: { selectedTab: 2 } });
        }
    };

    const handleFileChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        setFieldValue('image', file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleBack = () => {
        navigate("/owner/shops", { replace: true, state: { selectedTab: 2 } });
    };

    const isMobile = useIsMobile();

    return (
        <div style={isMobile ? {padding: "10px"} : {padding: "10px", width: "78%"}}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#1b5e3b"} marg={0}/>
                <h3 style={{textAlign: "center", position: "relative", top: "-30px"}}>{t("Category")}</h3>
            </div>
            <div className={styles.mainBlock}>
                <h4>{t('id')}: {categoryId || t('New')}</h4>
                <Formik
                    enableReinitialize
                    initialValues={{...initialValues, categoryName}}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({setFieldValue}) => (
                        <Form className={styles.form}>
                            <div style={{position: "relative"}} className={styles.mainBlockOne}>
                                <label htmlFor="categoryName" className={styles.mainBlockLabel}>
                                    {t("Category name")}
                                </label>
                                <Field
                                    type="text"
                                    id="categoryName"
                                    name="categoryName"
                                    className={styles.mainBlockDownInput}
                                />
                                <div style={{position: "absolute", top: "10px", right: "10px"}}>
                                    <EditIcon/>
                                </div>

                                <ErrorMessage name="categoryName" component="div" className={styles.error}/>
                            </div>

                            <div className={styles.mainBlockDownImage}>
                                <label htmlFor="image" className={styles.mainBlockLabel}>
                                    {t("Image for category")}
                                </label>
                                {(image || imagePreview) ? "" : <IconImage/>}
                                {(image || imagePreview) && (
                                    <img className={styles.preview}
                                         src={imagePreview || process.env.REACT_APP_MEDIA_URL + image}
                                         alt={t("Preview of the selected image")}/>
                                )}
                                <div className={styles.fileInputWrapper}>
                                    <input
                                        type="file"
                                        id="image"
                                        name="image"
                                        className={styles.fileInput}
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                    />
                                    <span
                                        className={styles.fileInputLabel}>{id ? t("Edit image") : t("Select image")}</span>
                                </div>
                                <ErrorMessage name="image" component="div" className={styles.error}/>
                            </div>

                            <div className={styles["button-container"]}>
                                <button type="submit" className={styles["add-button"]}>
                                    {id ? t("Edit category") : t("Save category")}
                                </button>
                                {id && <button type="button" onClick={() => setOpenDelete(true)}
                                               className={styles["removeButton"]}>
                                    {t("Delete")}
                                </button>}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <StyledDialog
                open={openDelete}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDelete}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("Are you sure you want to delete category?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>{t("Cancel")}</Button>
                    <Button onClick={handleDelete}>{t("Delete")}</Button>
                </DialogActions>
            </StyledDialog>

            <StyledDialog
                open={openSaveConfirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseSaveConfirm}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {id ? t("Do you want to edit the category?") : t("Do you want to create the new category?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSaveConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleConfirmSubmit}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default CategorySettingItem;
