import React, {useEffect, useState} from 'react';
import styles from "./VerificationModal.module.scss"

import {t} from "i18next";
import CloseVerification from "./CloseVerification/CloseVerification";
import {getActiveChats} from "../../api/getContentsApi/getFirstTextRecord";
import FaceModalRecord from "./FaceModalRecord/FaceModalRecord";

const VerificationModal = ({setOpenVerificatModal, setVerificated}) => {
    const [dataReq, setDataReq] = useState("");  // Здесь будем хранить данные для текста

    // Загружаем данные через useEffect
    useEffect(() => {
        const fetchData = async () => {
            const response = await getActiveChats();
            setDataReq(response);
        };

        fetchData();
    }, []);

    return (
        <div className={styles.bossConteiner}>
            <div className={styles.mainConteiner}>
                <CloseVerification setOpenVerificatModal={setOpenVerificatModal} />
                <h2 className={styles.mainTitle}>{t("Verification")}</h2>
            </div>
            <div className={styles.profileCard}>
                <div className={styles.wordVerificate}>
                    <span className={styles.fontVerif}>{t("Say text to camera: ")}</span>
                    <span className={styles.mainContText}>{dataReq.text}</span>  {/* отображаем текст для пользователя */}
                </div>
                <div className={styles.downConteiner}>
                    <div className={styles.infoBlock}>
                        <FaceModalRecord setVerificated={setVerificated} setOpenVerificatModal={setOpenVerificatModal}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationModal;