import React, {useRef, useState, useMemo} from 'react';
import styles from "./ModalProduct.module.scss";

import rootStore from "../../store/RootStore";
import IconForward from "../Icons/IconForward";
import MinusIcon from "../Icons/MinusIcon";
import PlusIcon from "../Icons/PlusIcon";
import {getMediaContent, setMediaContent, unknownImage} from "../../helpers";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import {getCurrencySymbol} from "../../helpers/getCurrency";

const mokeData = {
    "productName": "Name product",
    "price": 12.5,
    "currency": "$",
    "stockQuantity": 5,
    "description": "lorem declor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "shopID": 111,
    "categoryID": 2,
    "imageUrl": 'https://imageproxy.wolt.com/menu/menu-images/63f8c0723ebc4edae021c951/8c0320e4-c0e2-11ee-9a04-16242b418f30_4860119261558.jpg',
}

const ModalProduct = ({children, product}) => {
    const {
        ProductID,
        product_images,
        ProductName,
        Description,
        Product_info,
        Price,
        shop,

    } = product;
    const isLogin = "Log in";
    const {openCloseL} = rootStore.systemStore;
    const {isAuth} = rootStore.userStore;
    const {addProductToCartOrder} = rootStore.cartOrderStore;
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [productInfoPage, setProductInfoPage] = useState(false);
    const [count, setCount] = useState(1);
    const productImage = product_images[0]?.ImageUrl || ""
    const modalRef = useRef(null);
    const symbol = getCurrencySymbol();
    const {setOpenMediaState, openMediaFull} = rootStore.systemStore;

    const toggleProductInfo = () => {
        setProductInfoPage(productInfoPage => !productInfoPage);
        setIsOpen(true)
    };

    const isSearchModal = window.location.pathname === '/discovery/search-store'

    const changeCount = (value) => {
        if (value === 'plus') {
            setCount(count => ++count);
        } else if (value === 'minus') {
            setCount(count => --count);
        }
    }

    const handleClickChildren = (event) => {
        if (event.target.id != "add-product") {
            setIsOpen(true)
        }
    }

    const handleAddProduct = () => {
        if (!isAuth) {
            if (window.innerWidth > 640) {
                openCloseL(isLogin)
            } else {
                navigate('/discovery/me')
            }
        } else {
            addProductToCartOrder(ProductID, count)
        }
        setIsOpen(false)
    }

    const handleGoToShop = () => {
        navigate(`/stores/${shop.ShopID}`)
    }

    const handleCloseModal = () => {
        setProductInfoPage(false)
        setIsOpen(false);
    };

    const videoUrl = useMemo(() => {
        if (product?.product_videos[0]?.VideoUrl) {
            return product?.product_videos[0]?.VideoUrl;
        }
    }, [product?.product_videos])

    const setCOntentHandle = () => {
        setOpenMediaState()
        setMediaContent(
            {
                "image": productImage,
                "video": product?.product_videos[0]?.VideoUrl
            }
        )
    }
    return (
        <>
            {isOpen && (
                <div className={styles["modal-overlay"]}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        {!productInfoPage && (
                            <>
                                <div className={styles["header-wrapper"]}>
                                    <div className={styles.closeBtnConteiner}>
                                        <button className={styles.buttonBlock} data-backdrop="transparent"
                                                data-size="medium" data-variant="neutral" type="button"
                                                aria-label="Close the dialog" data-test-id="modal-close-button"
                                                onClick={handleCloseModal}>
                                            <div className={styles.fakeBtnBlock}></div>
                                            <div className={styles.closeImgBtn}>
                                                <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                                    <path
                                                        d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                                </svg>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div onClick={() => setCOntentHandle()}>
                                    {
                                        videoUrl ?
                                            (
                                                <div className={styles.mainContentBlockImg}>
                                                    <video className={styles.mainImg} loop="loop"
                                                           autoPlay="autoplay" muted="muted" playsInline={true}>
                                                        <source
                                                            src={process.env.REACT_APP_MEDIA_URL + videoUrl}
                                                        />
                                                    </video>
                                                </div>
                                            ) :
                                            (
                                                <div className={styles["image-wrapper"]}>
                                                    <img className={styles["image-order"]} loading="lazy"
                                                         src={process.env.REACT_APP_MEDIA_URL + productImage || unknownImage}
                                                         alt="ProductImage"/>
                                                </div>
                                            )
                                    }
                                </div>
                                <div className={styles.contentBox}>
                                    <h2 className={styles["header"]}>{ProductName}</h2>
                                    <p className={styles["price"]}>{symbol} {product.price_in_user_currency}</p>
                                    <p className={styles["description"]}>{Description}</p>
                                    <button className={styles["element"]} onClick={toggleProductInfo}>
                                        <p className={styles["element-description"]}>{t("Product info")}</p>
                                        <IconForward/>
                                    </button>
                                    <div className={`${styles["quantity-wrapper"]} ${styles.goToShop}`}>
                                        {
                                            isSearchModal ?
                                                (
                                                    <button className={`${styles["add-button"]} ${styles.goToShop}`}
                                                            onClick={() => handleGoToShop()}>
                                                        <span
                                                            className={styles["add-button--element"]}>{t("Start order")}</span>
                                                    </button>
                                                ) :
                                                (
                                                    <>
                                                        <div className={styles["quantity"]}>
                                                            <button
                                                                className={styles["quantity--element"]}
                                                                disabled={count <= 1}
                                                                onClick={() => changeCount('minus')}>
                                                                <MinusIcon size={20} color="#1b5e3b"/>
                                                            </button>
                                                            <p className={styles["quantity--count"]}>
                                                                {count}
                                                            </p>
                                                            <button
                                                                style={{textAlign: "center"}}
                                                                className={styles["quantity--element"]}
                                                                onClick={() => changeCount('plus')}>
                                                                <PlusIcon size={16} color="#1b5e3b"/>
                                                            </button>
                                                        </div>
                                                        <button className={styles["add-button"]}
                                                                onClick={() => handleAddProduct()}>
                                                            <span
                                                                className={styles["add-button--element"]}>{t("Add to order")}</span>
                                                            <span
                                                                className={styles["add-button--element"]}
                                                                style={{marginLeft: "5px"}}>{symbol} {product.price_in_user_currency * count} </span>
                                                        </button>
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                        {productInfoPage && (
                            <div className={styles.fantomConteiner}>
                                <div className={styles.infoTestCont}>
                                    <div className={styles.productInfoModal}>
                                        <div className={styles["header-wrapper"]}>
                                            <div onClick={toggleProductInfo}></div>
                                            <p className={styles["description2"]}>{t("Product info page")}</p>
                                            <div className={styles["close-button"]} onClick={handleCloseModal}>
                                                <button className={styles.closeBtn}>x</button>
                                            </div>
                                        </div>
                                        <h2 className={styles["header"]}>{ProductName}</h2>
                                        <p style={{maxHeight:"350px", padding:"1rem"}} className={styles["description"]}>{Description}</p>
                                        {/*<p className={styles["description"]}>{Product_info}</p>*/}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div onClick={(event) => handleClickChildren(event)}> {children}</div>
        </>
    );
};

export default ModalProduct;