import React, {useCallback, useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";
import styles from "./VerificationScanner.module.scss";
import { t } from "i18next";
import * as faceapi from "face-api.js";

const VerificationScanner = ({ onCapture, onClose }) => {
    const webcamRef = useRef(null);
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        loadModels();
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const loadModels = async () => {
        await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
    };

    const detectDocument = useCallback(async () => {
        if (!webcamRef.current || isScanning) return;

        const video = webcamRef.current.video;
        if (!video) return;

        const canvas = faceapi.createCanvasFromMedia(video);
        const displaySize = { width: video.videoWidth, height: video.videoHeight };
        faceapi.matchDimensions(canvas, displaySize);

        const detections = await faceapi.detectAllFaces(video, new faceapi.SsdMobilenetv1Options());

        if (detections.length > 0) {
            console.log("Документ найден, делаем снимок...");
            capture();
        } else {
            requestAnimationFrame(detectDocument);
        }
    }, [isScanning]);

    useEffect(() => {
        const interval = setInterval(detectDocument, 500);
        return () => clearInterval(interval);
    }, [detectDocument]);

    const capture = () => {
        if (!webcamRef.current) return;
        setIsScanning(true);

        const imageSrc = webcamRef.current.getScreenshot();
        if (!imageSrc) return;

        // Преобразуем base64 в Blob
        const byteString = atob(imageSrc.split(",")[1]);
        const mimeString = imageSrc.split(",")[0].split(":")[1].split(";")[0];

        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeString });

        // Создаём файл для отправки
        const file = new File([blob], "document_scan.png", { type: mimeString });

        onCapture(file); // Передаём файл в `onCapture`
        setTimeout(() => setIsScanning(false), 3000); // Защита от повторного снимка
    };

    return (
        <div className={styles.modal}>
            <Webcam
                ref={webcamRef}
                screenshotFormat="image/png"
                className={styles.cameraView}
                videoConstraints={{ facingMode: "environment" }}
            />
            <div className={styles.overlay}>
                <div className={styles.passportFrame}></div>
                <p className={styles.text}>{t("Align your passport or ID card within the frame")}</p>
            </div>
            <button className={styles.closeBtn} onClick={onClose}>{t("Close")}</button>
        </div>
    );
};

export default VerificationScanner;