import React from "react";
import { deleteUserSearchHistory as deleteApi } from "../../api/addContent/deleteUserSearchHistory";
import styles from "./SearchBody.module.scss";
import rootStore from "../../store/RootStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { t } from "i18next";
import { ReactComponent as Logo } from "./clock-svg.svg";
import { ReactComponent as LogoWhite } from "./clock-white-svg.svg";
import { useTheme } from "../../helpers/ThemeContext";

const RecentSearch = () => {
  const { deleteUserSearchHistory, getUserSearchHistory, setSearchText } =
    rootStore.searchStore;
  const {setIsFocusInput} = rootStore.systemStore;
  const { themeMode } = useTheme();

  const deleteData = async () => {
      setIsFocusInput(false);
    const response = await deleteApi();
    if (response && (response.status === 200 || response.status === 204)) {
      deleteUserSearchHistory();
    }
  };

  return (
    <div id="recent-search" className="recent-search">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
        }}
      >
        <p>Recent searches</p>
        <button
          className={styles.btnMoreInfo}
          onClick={() => {
            deleteData();
          }}
        >
          {t("Clear")}
        </button>
      </div>
      <div>
        <ul>
          {toJS(getUserSearchHistory).map((element) => {
            return (
              <li
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  // borderBottom: "2px solid",
                  borderTop: "1px solid rgba(11, 12, 12, 0.1)",
                  margin: 0,
                  padding: "8px 0 8px 0",
                }}
                onClick={() => setSearchText(element.text)}
                key={element.id}
              >
                {/* <ClockSvg /> */}
                {themeMode === "dark" ? <LogoWhite /> : <Logo />}
                <p style={{ fontWeight: 400 }}>{element.text || ""}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default observer(RecentSearch);
