import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import styles from "./TransactionsPage.module.scss"
import rootStore from "../../store/RootStore";
import WithAuthUser from "../../helpers/WithAuthUser";
import {useNavigate} from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";
import TransactionCard from "./TransactionCard/TransactionCard";
import {observer} from "mobx-react";
import TransactionMainCard from "./TransactionMainCard/TransactionMainCard";

const TransactionsPage = () => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState()

    const { loadTransactionsals, getTransactions} = rootStore.userStore;
    const [transactions, setTransactions] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        if (!open) {
            navigate("/discovery/me/balance");
        }
    }, [open, navigate])

    useEffect(() => {
        loadTransactionsals()
    }, [])

    useEffect(() => {
        if(getTransactions) {
            setTransactions(getTransactions)
        }
    }, [getTransactions]);

    const sortedTransactions = transactions.slice().sort((a, b) => {
        const createdIndex = ['Created', 'Canceled'].indexOf(a.status);
        const canceledIndex = ['Created', 'Canceled'].indexOf(b.status);
        return createdIndex - canceledIndex;
    });

    return (
        <WithAuthUser>
            {
                !open ?
                    (
                        <>
                            <div className={styles.upperBlock}
                                 onClick={() => navigate(-1)}>
                                <IconBack size={20} pure={true} fill={"#1b5e3b"}/>
                                <h2 className={styles.mainTitle}>{t("Transactions")}</h2>
                            </div>

                            <div className={styles.profileCard}>
                                <div className={styles.downConteiner}>
                                    {
                                        sortedTransactions.map((transaction, index) => (
                                            <TransactionCard transaction={transaction} setOpen={setOpen} setData={setData} key={index}/>))
                                    }
                                </div>
                                <button className={styles.payBtn} onClick={() => navigate('up')}>{t("Top up balance")}</button>
                            </div>
                        </>
                ) :
                (
                    <TransactionMainCard data={data} setOpen={setOpen}/>
                )
            }
        </WithAuthUser>
    );
};

export default observer(TransactionsPage);