import React, { useEffect, useState } from 'react';
import { getOwnerVerifyText } from "../../api/getContentsApi/getOwnerVerifyText";
import {
    Box, Button,
    CircularProgress, Dialog,
    DialogTitle, Slide,
    styled
} from "@mui/material";
import styles from "./OwnerVerificationText.module.scss";
import { useTranslation } from "react-i18next";
import { deleteOwnerVerifyText } from "../../api/addContent/deleteOwnerVerifyText";
import { addOwnerVerifyText } from "../../api/addContent/addOwnerVerifyText";

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 20px !important;
    }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OwnerVerificationText = () => {
    const { t } = useTranslation();
    const [dataText, setTextData] = useState("");
    const [dataId, setIdData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(null);

    const fetchText = async () => {
        try {
            setLoading(true);
            const data = await getOwnerVerifyText();
            if (data.length > 0) {
                setTextData(data[0].text);
                setIdData(data[0].id.toString());
            }
        } catch (error) {
            console.error("An error occurred while fetching users:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchText();
    }, []);

    const handleChange = (e) => {
        setTextData(e.target.value);
    };

    const handleSave = async () => {
        if (!dataText) return;
        await addOwnerVerifyText(dataId, dataText);
        await fetchText();
        setDialogOpen(true);
    };

    const handleDelete = async () => {
        await deleteOwnerVerifyText(dataId);
        await fetchText();
        setTextData("")
        setIdData(null);
        setDialogOpen(true);
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <h4 style={{ marginBottom: "20px" }}>{t("Verification text")}</h4>
            <input
                type="username"
                name="username"
                id="method-select-username"
                placeholder="Verification text"
                autoComplete="off"
                spellCheck="false"
                className={styles.fieldInp}
                value={dataText}
                onChange={handleChange}
            />
            <div className={styles["button-container"]}>
                <button onClick={handleSave} type="submit" className={styles["add-button"]}>
                    {t("Save")}
                </button>
                {dataId && (
                    <button type="button" onClick={handleDelete} className={styles["removeButton"]}>
                        {t("Delete")}
                    </button>
                )}
            </div>
            <StyledDialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Verification text updated")}</DialogTitle>
                <Button onClick={() => setDialogOpen(false)}>{t("Ok")}</Button>
            </StyledDialog>
        </div>
    );
};

export default OwnerVerificationText;
