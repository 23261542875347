import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import styles from './NewShopSliderCard.module.scss';
import {observer} from "mobx-react";
import {getCurrencySymbol} from "../../../../helpers/getCurrency";
import {Skeleton} from "@mui/material";

const NewShopSliderCard = ({ product, countP }) => {
    const currencySymbol = getCurrencySymbol();
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        console.log("TRUE LOAD")
        setImageLoaded(true);
    };
    console.log("TEST:", product?.product_images[0]?.ImageUrl)
    useEffect(() => {
        console.log("FALSE LOAD")
        setImageLoaded(false);
    }, [product]);
    return (
        <div className={styles.mainConteiner} style={{width: countP < 2 ? "50%" : "100%"}}>
            <Link className={styles.card} to={`/stores/${product.shop.ShopID}`}>
                <div>
                    <div tabIndex={"-1"} className={styles.mainCardBlock}>
                        <div className={styles.imgConteiner}>
                            <div className={styles.imgBlock}>
                                <div className={styles.image}>
                                    {!imageLoaded && (
                                        <Skeleton className={styles.skeleton} />
                                    )}
                                    <img
                                        className={styles.img}
                                        src={process.env.REACT_APP_MEDIA_URL + product?.product_images[0]?.ImageUrl}
                                        alt={"text"}
                                        key={product?.product_images[0]?.ImageUrl}
                                        onLoad={handleImageLoad}
                                        onError={() => setImageLoaded(false)}
                                        style={{ display: imageLoaded ? 'block' : 'none' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.descriptionBlock}>
                            <span className={styles.fontName}>{product.Price} {currencySymbol}</span>
                            <span className={styles.productName}>{product.ProductName}</span>
                        </div>
                        <div className={styles.dotted}></div>
                        <div className={styles.downBlockDescription}>
                            <span className={styles.downShopName}>{product?.shop.ShopName}</span>
                            <div className={styles.downShopBlock}>
                                <span className={styles.downShopTaks}>{product?.shop.Base_delivery_fee} {currencySymbol}</span>
                                <span className={styles.dotTitl}>&bull;</span>
                                <span className={styles.downShopDelivery}>{product?.shop.Estimated_delivery_time} min</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default observer(NewShopSliderCard);
