import api from "../api";

export const unBanUser = async ( userId) => {
    try {
        await api.patch(
            `/owner_site/verifications/users/${userId}/unban/`
        )
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
};
