import api from "../api";

export const addRoleToUser = async (data, userId) => {
    try {
        await api.post(
            `owner_site/verifications/users/${userId}/client-status-role/`,
            data
        )
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
};
