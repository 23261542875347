import React, { useEffect, useState, useRef } from "react";
import styles from './OwnerShopPage.module.scss';
import { t } from "i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Slider from "react-slick";
import { CustomTabPanel } from "../../components/CustomTabPanel/CusmomTabPanel";
import { a11yProps } from "../../helpers/a11yProps";
import { OwnerShopList } from "../../components/OwnerShopList/OwnerShopList";
import { FiltredOwnerShopList } from "../../components/FiltredOwnerShopList/FiltredOwnerShopList";
import { OwnerCategoryList } from "../../components/OwnerCategoryList/OwnerCategoryList";
import { useLocation } from "react-router-dom";
import { OwnerDiscoveryList } from "../../components/OwnerDiscoveryList/OwnerDiscoveryList";
import { styled } from "@mui/material";
import OwnerVerificationText from "../../components/OwnerVerificationText/OwnerVerificationText";
import SearchTagOwner from "../../components/SearchTagOwner/SearchTagOwner";
import Tabs from "@mui/material/Tabs";
import useIsMobile from "../../helpers/useIsMobile";
import {StyledTabs} from "../../components/StyledTabs/StaledTabs";

const OwnerShopPage = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const isMobile= useIsMobile()

    useEffect(() => {
        if (location?.state && location?.state?.selectedTab) {
            setValue(location?.state?.selectedTab || 0);
        }
    }, [location]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t('Shops management')}</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{borderRight: 1, borderColor: 'divider', minWidth: isMobile ? 256 : 300}}
                    >
                        <Tab label={t("All shops")} {...a11yProps(0)} onClick={(e) => handleChange(e, 0)} selected={value === 0} />
                        <Tab label={t("Search")} {...a11yProps(1)} onClick={(e) => handleChange(e, 1)} selected={value === 1} />
                        <Tab label={t("Category")} {...a11yProps(2)} onClick={(e) => handleChange(e, 2)} selected={value === 2} />
                        <Tab label={t("Discovery")} {...a11yProps(3)} onClick={(e) => handleChange(e, 3)} selected={value === 3} />
                        <Tab label={t("Verification text")} {...a11yProps(4)} onClick={(e) => handleChange(e, 4)} selected={value === 4} />
                        <Tab label={t("Search tags")} {...a11yProps(5)} onClick={(e) => handleChange(e, 5)} selected={value === 5} />
                        <Tab label={t("Unknown")} {...a11yProps(6)} onClick={(e) => handleChange(e, 6)} selected={value === 6} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <OwnerShopList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <FiltredOwnerShopList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <OwnerCategoryList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <OwnerDiscoveryList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <OwnerVerificationText />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <SearchTagOwner />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                    <p>Unknown</p>
                </CustomTabPanel>
            </Box>
        </div>
    );
};

export default OwnerShopPage;
