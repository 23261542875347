import { makeAutoObservable} from 'mobx';
import api from "../api/api";

class SearchStore {
    searchData = {
        products: [],
        shops: []
    };
    searchText = "";
    refresh = true;

    searchCategoryDiscoveryName = ""
    searchCategoryStoreRestaurants = ""
    searchCategoryStoreName = ""

    searchCategoryData = []
    historySearch = []
    searchTags = []
    isOpenSeeAll = false;

    get getSearchData(){
        return this.searchData;
    }
    get getIsOpenSeeAll() {
        return this.isOpenSeeAll;
    }
    get getSearchTags(){
        return this.searchTags;
    }
    get getSearchText(){
        return this.searchText;
    }
    get getSearchCategoryStoreName(){
        return this.searchCategoryStoreName;
    }
    get getSearchCategoryStoreRestaurants(){
        return this.searchCategoryStoreRestaurants;
    }
    get getUserSearchHistory(){
        return this.historySearch;
    }

    constructor() {
        makeAutoObservable(this);
    }

    setSearchData = (data) => {
        this.searchData = data;
    }

    setIsOpenSeeAll = (state) => {
        this.isOpenSeeAll = state;
    }

    setSearchText = (text) => {
        this.searchText = text;
        if(this.searchText === ""){
            this.searchData = []
        } else {
            this.getSearchDataAPI()
        }
    }

    setSearchTags = (tags) => {
        this.searchTags = tags;
    }
    
    setUserSearchHistory = (history) => {
        this.historySearch = history;
    }

    deleteUserSearchHistory = () => {
        this.historySearch = [];
    }

    destroySearchCategory = (route) => {
        if(route === "restaurants") {
            localStorage.removeItem("searchCategoryRestaurants")
            this.searchCategoryStoreRestaurants = ""
        }
        else if(route === "stores") {
            localStorage.removeItem("searchCategoryStores")
            this.searchCategoryStoreName = ""
        }
        else if(route === "discovery") {
            localStorage.removeItem("searchCategoryDiscovery")
            this.searchCategoryDiscoveryName = ""
        }
    }

    setSearchCategory = (text, route= '') => {
        this.refresh = false
        if(route === "restaurants") {
            localStorage.setItem("searchCategoryRestaurants", JSON.stringify({id:text.id,name:text.name}))
            this.searchCategoryStoreRestaurants = text
        }
        else if(route === "stores") {
            localStorage.setItem("searchCategoryStores", JSON.stringify({id:text.id,name:text.name}))
            this.searchCategoryStoreName = text
        }
        else if(route === "discovery") {
            localStorage.setItem("searchCategoryDiscovery", JSON.stringify({id:text.id,name:text.name}))
            this.searchCategoryDiscoveryName = text
        }
    }

    getSearchDataAPI = async() => {
        api.get('/search-new-product/?q=' + this.getSearchText).then(response => {
            this.setSearchData(response.data)
            console.log("INIT STATE")
        }).catch(error => {
            console.log(error)
            throw error.response.data;
        })
    }

    getSearchTagsAPI = async() => {
        api.get('/search-tags/').then(response => {
            this.setSearchTags(response.data)
        }).catch(error => {
            console.log(error)
            throw error.response;
        })
    }
}

const searchStore = new SearchStore();
export default searchStore;
