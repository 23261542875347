import React from 'react';

const MinusIcon = ({size="17px",color="#1b5e3b"}) => {
    return (
        <div style={{width: size, height: size}}>
            <svg viewBox="0 0 24 24" aria-hidden="true" fill={color}>
                <path
                      d="M5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"></path>
            </svg>
        </div>
    );
};

export default MinusIcon;