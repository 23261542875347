import React, { useEffect, useState } from 'react';
import { getSearchOwnerTags } from "../../api/getContentsApi/getSearchOwnerTags";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    styled as muiStyle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";

import styled  from "styled-components";

import { useTranslation } from "react-i18next";
import { addOwnerSearchTag } from "../../api/addContent/AddOwnerSearchTag";

import { deleteOwnerSearchTag } from "../../api/addContent/deleteOwnerSearchTag";
import useIsMobile from "../../helpers/useIsMobile";

const StyledTableCell = muiStyle(TableCell)(({ theme }) => ({
    padding: '7px',
}));

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 20px !important;
        width: 80vw;
    }
`;

const SearchTagOwner = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [errorMessages, setErrorMessages] = useState(null);

    const isMobile = useIsMobile();

    const fetchSearchTags = async () => {
        try {
            setLoading(true);
            const data = await getSearchOwnerTags();
            if (data) {
                setDataList(data);
            }
        } catch (error) {
            console.error("An error occurred while fetching users:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSearchTags();
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!orderNumber) newErrors.orderNumber = 'Order number is required';
        if (!name) newErrors.name = t("Search tag is required");
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            try{
                await addOwnerSearchTag(orderNumber, name);
                await fetchSearchTags();
                setOrderNumber("");
                setName("");
                setOpen(false);
                setErrors({})
                setErrorMessages(null);
            }catch(error){
                if (error.response && error.response.data) {
                    setErrorMessages(error.response.data.errors || ["An unexpected error occurred."]);
                } else {
                    setErrorMessages(["An unexpected error occurred."]);
                }
            }
        } else {
            setErrors(formErrors);
        }
    };

    const handleDelete = async (id) => {
        await deleteOwnerSearchTag(id);
        fetchSearchTags();
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setName("")
        setOrderNumber('')
        setOpen(false);
        setErrors({})
        setErrorMessages(null);
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            {dataList.length === 0 ? <p>{t("No search tags")}</p>
                : <Paper sx={{width: '100%', marginBottom: '20px'}}>
                <TableContainer sx={{maxHeight: "60vh"}}>
                    <Table sx={isMobile ? {maxWidth: 370} : ""} aria-label="users table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{width: 100}}>{t("Search tag")}</StyledTableCell>
                                <StyledTableCell sx={{width: 40}}>{t("Order number")}</StyledTableCell>
                                <StyledTableCell sx={{width: 40}}>{t("Delete")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tag) => (
                                <TableRow key={tag.id}>
                                    <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                        <p style={{fontWeight:400}}>{tag.name}</p>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                        <p style={{fontWeight:400}}>{tag.order_number}</p>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{maxWidth: 100, wordWrap: 'break-word'}}>
                                        <Button onClick={() => handleDelete(tag.id)}>{t("Delete")}</Button>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={dataList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}
            <Button variant="contained" color="primary" onClick={handleOpenModal}>
                {t("Add Search Tag")}
            </Button>
                <StyledDialog sx={{borderRadius:"20px"}} open={open} onClose={handleCloseModal}>
                    <DialogTitle>{t("Add Search Tag")}</DialogTitle>
                    <DialogContent>
                        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField
                                label={t("Order Number")}
                                type='number'
                                value={orderNumber}
                                onChange={(e) => setOrderNumber(e.target.value)}
                                error={!!errors.orderNumber}
                                helperText={errors.orderNumber}
                            />
                            <TextField
                                label="Search tag"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                            {errorMessages && (
                                <div style={{color: "red", marginTop: "10px"}}>
                                    {Object.entries(errorMessages).map(([key, value]) => (
                                        <div key={key}>{value}</div>
                                    ))}
                                </div>
                            )}
                            <DialogActions>
                                <Button onClick={handleCloseModal} color="primary">
                                    {t("Cancel")}
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    {t("Submit")}
                                </Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </StyledDialog>
        </div>
    );
};

export default SearchTagOwner;
