import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import styles from './CategoryItem.module.scss';
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import useScrollPosition from "../../helpers/useScrollPosition";
import {Skeleton} from "@mui/material";

const CategoryItem = ({ category }) => {
    const { setSearchCategory } = rootStore.searchStore;

    const [imageUrl, setImageUrl] = useState(null);
    const [altText, setAltText] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false); // Стейт для отслеживания загрузки изображения
    const categoryId = category?.CategoryID;

    useEffect(() => {
        if (category && categoryId) {
            setImageUrl(category.category_images[0]?.ImageUrl);
            setAltText(category.category_images[0]?.AltText);
        }
    }, [category]);

    useScrollPosition("Store");
    const [route, setRoute] = useState('');
    useEffect(() => {
        if (window.location.pathname.includes("/restaurants")) {
            setRoute('restaurants');
        } else if (window.location.pathname.includes("/stores")) {
            setRoute('stores');
        } else if (window.location.pathname.includes("/discovery")) {
            setRoute('discovery');
        }
    }, [window.location.pathname]);

    const handleSelectTarget = () => {
        setSearchCategory({ "id": category?.CategoryID, "name": category?.CategoryName }, route);
    };

    // Функция для обработки загрузки изображения
    const handleImageLoad = () => {
        setImageLoaded(true); // Устанавливаем состояние, что изображение загружено
    };

    return (
        <div className={styles.mainConteiner}>
            <Link className={styles.card} onClick={() => handleSelectTarget()}>
                <div>
                    <div tabIndex={"-1"} className={styles.mainCardBlock}>
                        <div className={styles.imgConteiner}>
                            <div className={styles.imgBlock}>
                                <div className={styles.image}>
                                    {!imageLoaded && <Skeleton className={styles.skeleton} />}
                                    {imageUrl && (
                                        <img
                                            className={styles.img}
                                            src={process.env.REACT_APP_MEDIA_URL + imageUrl}
                                            alt={altText}
                                            onLoad={handleImageLoad} // Обработчик события загрузки изображения
                                            style={{ opacity: imageLoaded ? 1 : 0 }} // Изображение будет видно только после загрузки
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.descriptionBlock}>
                            <div className={styles.description}>
                                <h3 className={styles.title}>{category?.CategoryName}</h3>
                                <p className={styles.count}>{category?.places} places</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default observer(CategoryItem);
