import React, {useEffect, useState} from 'react';
import styles from './BasketButton.module.scss'
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import Badge from "../../Icons/Badge";
import useIsMobile from "../../../helpers/useIsMobile";

const BasketButton = ({scrolledShop= false}) => {
    const [isTransparent, setIsTransparent] = useState(true);
    useEffect(() => {
        function handleScrollHeader() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop === 0) {
                setIsTransparent(true);
            } else {
                setIsTransparent(false);
            }
        }

        window.addEventListener('scroll', handleScrollHeader);
    }, []);

    const { cartsList } = rootStore.cartOrderStore;
    const isStore = window.location.pathname.includes('/stores/');
    const isMobile = useIsMobile();

    return (
        <div className={`${styles.buttonWrapper}`}>
            <button aria-label="Your orders" data-test-id="visible-baskets.shopping-cart-button"
                    className={`
                        ${styles.button} 
                        ${isStore && !isMobile ? styles.button__Shop : ""}
                        ${scrolledShop ? styles.button__ShopScrolled : ""}
                        ${!isTransparent? styles.button__scrolled:""}
                     `}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
                        stroke="#fff" stroke-width="2.15" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            {cartsList.cart_total_items > 0 && <Badge content={cartsList.cart_total_items}/>}
        </div>
    );
};

export default observer(BasketButton);