import React, {useEffect, useState} from 'react';
import styles from './BalancePage.module.scss'
import img from "./img.svg"
import image from "./image.png"
import rootStore from "../../../store/RootStore";
import {useNavigate} from "react-router-dom";
import IconBack from "../../Icons/IconBack";
import {useTheme} from "../../../helpers/ThemeContext";
import {Alert} from "@mui/material";
import {observer} from "mobx-react";

const BalancePage = () => {
    const { getProfile, topUpBalance, loadTransactionsals, getStatusPay } = rootStore.userStore;
    const [value, setValue] = useState();
    const navigate = useNavigate();
    const { themeMode } = useTheme()

    useEffect(() => {
        getProfile()
    }, []);

    const handlePay = () => {
        topUpBalance(value, navigate)
    }
    return (
        <>
        <div className={styles.backer} style={{
            "position": "fixed",
            "width": "32px",
            "height": "32px",
            "zIndex": 9999999,
            "top": "1%",
            "left": "3%",
        }}
             onClick={() => navigate(-1)}><IconBack size={20} pure={true} fill={"#1b5e3b"}/></div>
    <div className={styles.lvl1}>
        <div className={styles.lvl2}>
            <div className={styles.lvl3}>
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBlock}>
                        <div className={styles.svgBlockCont}>
                        <div className={styles.svgBlockMain}>
                                    <div className={styles.svgBlockM}>
                                        <div className={styles.svgBlockB}>
                                            {themeMode === "light" ? <img src={img} alt="React Logo"/> : <img style={{width: "100%"}} src={image} alt="React Logo"/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.middleConteiner}>
                                <h2 className={styles.middleConteinerTitle}>
                                    Top up your balance</h2>
                                <div className={styles.middleBlock}>
                                    <div className={styles.middleBlockNum}>1</div>
                                    <span>Convenient and quick balance replenishment.</span>
                                </div>
                                <div className={styles.middleBlock}>
                                    <div className={styles.middleBlockNum}>2</div>
                                    <span>Enter the desired top-up amount and click Pay.</span>
                                </div>
                            </div>
                            <div className={styles.downButton}>
                                <button  type="button" className={styles.btn}>How it's work?</button>
                            </div>
                            <div className={styles.mainButtonCont}>
                                <button className={styles.mainButton}>
                                    <div className={styles.mainButtonContent}>
                                        <div className={styles.spanCont}>
                                            <span className={styles.spanOne}>Amount:</span>
                                            <input type={"number"}
                                                   className={styles.imgBlock}
                                                   placeholder={""}
                                                   value={value}
                                                   onChange={(e) => setValue(e.target.value)}
                                            ></input>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            { getStatusPay && (<Alert sx={{marginBottom: "1rem"}} severity="error">{getStatusPay}</Alert>) }
                            <button className={styles.payBtn} onClick={() => handlePay()}>Pay of</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default observer(BalancePage);