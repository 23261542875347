import api from "../api";

export const getOwnerVerifyText = async () => {
    try {
        const users = await api.get(`/owner_site/shops/orders/text-records/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}