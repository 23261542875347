import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import styles from './NewProductSeeAllCard.module.scss';
import {observer} from "mobx-react";
import {getCurrencySymbol} from "../../../../helpers/getCurrency";
import {Skeleton} from "@mui/material";

const NewProductSeeAllCard = ({ product, action }) => {
    const currencySymbol = getCurrencySymbol();
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleOpenModal = () => {
        action(product)
    }

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    useEffect(() => {
        setImageLoaded(false);
    }, [product]);

    return (
        <div className={styles.mainConteiner} onClick={() => handleOpenModal(true)}>
            <Link className={styles.card}>
                <div className={styles.coneinerBoss}>
                    <div tabIndex={"-1"} className={styles.mainCardBlock}>
                        <div className={styles.imgConteiner}>
                            <div className={styles.imgBlock}>
                                <div className={styles.image}>
                                    {!imageLoaded && (
                                        <Skeleton className={styles.skeleton} />
                                    )}
                                    <img
                                        className={styles.img}
                                        src={process.env.REACT_APP_MEDIA_URL + product?.product_images[0]?.ImageUrl}
                                        alt={"text"}
                                        onLoad={handleImageLoad}
                                        style={{ display: imageLoaded ? 'block' : 'none' }} // Показываем изображение после загрузки
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.descriptionBlock}>
                            <span className={styles.fontName}>{product.Price} {currencySymbol}</span>
                            <span className={styles.productName}>{product.ProductName}</span>
                        </div>
                        <div className={styles.dotted}></div>
                        <div className={styles.downBlockDescription}>
                            <span className={styles.downShopName}>{product?.shop.ShopName}</span>
                            <div className={styles.downShopBlock}>
                                <span className={styles.downShopTaks}>{product?.shop.Base_delivery_fee} {currencySymbol}</span>
                                <span className={styles.dotTitl}>&bull;</span>
                                <span className={styles.downShopDelivery}>{product?.shop.Estimated_delivery_time} min</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default observer(NewProductSeeAllCard);
