import api from "../api";

export const updateOwnerShop = async (formData,id) => {
    if(formData) {
        try {
            const apiResponse = await api.patch(`/owner_site/shops/shop/${id}/edit/owner/`,formData).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}