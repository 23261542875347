import api from "../api";

export const addOwnerVerifyText = async (id,value) => {
    if (!id){
        try {
            const users = await api.post(`/owner_site/shops/orders/text-records/`,{
                text: value
            });
            return users.data;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    try {
        const users = await api.patch(`/owner_site/shops/orders/text-records/${id}/`,{
            text: value
        });
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}