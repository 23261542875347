import React from 'react';
import styles from './OwnerVerificationPage.module.scss'
import {t} from "i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import AllUsersTable from "../../components/AllUsersTable/AllUsersTable";
import NotApprovedUserTable from "../../components/NotApprovedUserTable/NotApprovedUserTable";
import {StyledTabs} from "../../components/StyledTabs/StaledTabs";
import {CustomTabPanel} from "../../components/CustomTabPanel/CusmomTabPanel";
import {a11yProps} from "../../helpers/a11yProps";
import {AllRolesTable} from "../../components/AllRolesTable/AllRolesTable";
import BlackList from "../../components/BlackList/BlackList";


const OwnerVerificationPage = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t('Verification')}</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={t("Approved")} {...a11yProps(0)} />
                        <Tab label={t("All users")} {...a11yProps(1)} />
                        <Tab label={t("Black list")} {...a11yProps(2)} />
                        <Tab label={t("Roles")} {...a11yProps(3)} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <NotApprovedUserTable/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <AllUsersTable/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <BlackList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <AllRolesTable />
                </CustomTabPanel>
            </Box>
        </div>
    );
};

export default OwnerVerificationPage;