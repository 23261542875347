import React, {useState} from "react";
import styles from "./Slides.module.scss";
import {Skeleton} from "@mui/material";

const Slides = ({ data }) => {
    const [imageLoaded, setImageLoaded] = useState(false); // Состояние для отслеживания загрузки изображения

    // Функция для обработки события загрузки изображения
    const handleImageLoad = () => {
        setImageLoaded(true); // Изображение загружено
    };

    return (
        <div className={styles.mainConteiner}>
            <picture>
                {/* для формата AVIF (если он вообще будет) */}
                {data?.image?.endsWith(".avif") && (
                    <source
                        srcSet={`${process.env.REACT_APP_MEDIA_URL}${data?.image}`}
                        type="image/avif"
                    />
                )}
                {!imageLoaded && <Skeleton className={styles.sceleton}/>}
                <img
                    className={styles.imgBody}
                    src={`${process.env.REACT_APP_MEDIA_URL}${data?.image}`}
                    alt={data?.top_text || ""}
                    onLoad={handleImageLoad}
                    style={{ opacity: imageLoaded ? 1 : 0 }}
                />
            </picture>

            <div className={styles?.textOverlay}>
                <div className={styles.topText}>{data?.top_text}</div>
                <div className={styles.bottomText}>{data?.bottom_text}</div>
            </div>
        </div>
    );
};

export default Slides;