import React, { useEffect, useState } from 'react';
import styles from "./VerificationPage3.module.scss";
import rootStore from "../../store/RootStore";
import WithAuthUser from "../../helpers/WithAuthUser";
import { useNavigate } from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";
import { t } from "i18next";
import { toJS } from "mobx";

const VerificationPage3 = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Устанавливаем начальное состояние загрузки
    const [userData, setUserData] = useState(null); // Локальное состояние для хранения данных профиля
     // Функция для выполнения запроса профиля и обновления состояния
    const fetchData = async () => {
        try {
            await rootStore.userStore.getProfile(); // Делаем запрос на получение профиля
            const profileData = rootStore.userStore.userData; // Получаем данные из store
            setUserData(profileData); // Обновляем локальное состояние
        } catch (error) {
            console.error("Error fetching profile data:", error);
        } finally {
            setLoading(false); // Сбрасываем состояние загрузки
        }
    };

    // useEffect для выполнения запроса при монтировании компонента
    useEffect(() => {
        fetchData(); // Вызываем асинхронную функцию для получения данных
    }, []);

    const goBack = () => {
        navigate("/discovery/stores", { replace: true });
    };

    if (loading) {
        return <div>Loading...</div>; // Показываем индикатор загрузки, пока запрос не завершён
    }

    const data = userData?.profile?.verification_status; // Данные валидации из профиля
    const date = new Date(data?.status_date) || new Date();

    return (
        <WithAuthUser>
            <div
                style={{
                    position: "fixed",
                    width: "32px",
                    height: "32px",
                    top: "1%",
                    left: "3%",
                    zIndex: 999999,
                }}
                onClick={goBack}
            >
                <IconBack size={20} pure={true} fill={"#1b5e3b"} disabled={true} />
                <h2 className={styles.mainTitle}>{t("Verification")}</h2>
            </div>
            <div className={styles.svg}></div>
            <div className={styles.profileCard}>
                <p className={styles.cardText}>
                    Your application is currently being processed, please wait
                </p>
                <p className={styles.description}>
                    {`${date.getDate()} ${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}
                </p>
                <h2>{data?.status_name}</h2>
                <button className={styles.activeBtn} onClick={goBack}>
                    {t("Go to the store")}
                </button>
            </div>
        </WithAuthUser>
    );
};

export default VerificationPage3;
