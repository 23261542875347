import React, { useState } from "react";
import DiscoveryParent from "../../components/DiscoveryParent/DiscoveryParent";
import { observer } from "mobx-react";
import SearchBody from "./SearchBody";
import SearchField from "../../components/Header/SearchField/SearchField";
import styles from "./SearchPage.module.scss";
import rootStore from "../../store/RootStore";

const SearchPage = () => {
  const [isOpenAll, setIsOpenAll] = useState(false);
  const {getIsFocusInput, setIsFocusInput} = rootStore.systemStore;

  return (
    <DiscoveryParent>
      <div className={styles.mainBlock}>
        <SearchField
          isOpenAll={isOpenAll}
          setIsOpenAll={setIsOpenAll}
          setIsFocusInput={setIsFocusInput}
        />
        <SearchBody
          setIsOpenAll={setIsOpenAll}
          isOpenAll={isOpenAll}
          isFocusInput={getIsFocusInput}
        />
      </div>
    </DiscoveryParent>
  );
};

export default observer(SearchPage);
