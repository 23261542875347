import React from 'react';
import styles from "../../pages/OwnerVerificationPage/OwnerVerificationPage.module.scss";
import {t} from "i18next";
import Box from "@mui/material/Box";
import {StyledTabs} from "../StyledTabs/StaledTabs";
import Tab from "@mui/material/Tab";
import {a11yProps} from "../../helpers/a11yProps";
import {CustomTabPanel} from "../CustomTabPanel/CusmomTabPanel";
import NotApprovedUserTable from "../NotApprovedUserTable/NotApprovedUserTable";
import AllUsersTable from "../AllUsersTable/AllUsersTable";
import AllUsersTableBlackList from "../AllUsersTableBlackList/AllUsersTableBlackList";
import BannedUsersTableBlackList from "../BannedUsersTableBlackList/BannedUsersTableBlackList";

const BlackList = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <StyledTabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={t("All users")} {...a11yProps(0)} />
                        <Tab label={t("Black list")} {...a11yProps(1)} />

                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <AllUsersTableBlackList/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <BannedUsersTableBlackList/>
                </CustomTabPanel>

            </Box>
        </div>
    );
};

export default BlackList;