import React, { useState, useRef } from 'react';
import styles from "./ModalReset.module.scss";
import { t } from "i18next";

const ModalReset = ({ isOpen, onClose, onSubmit }) => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [step, setStep] = useState(1);
    const otpRefs = useRef([]);

    const handleOtpChange = (index, value) => {
        if (!/^\d?$/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            otpRefs.current[index + 1]?.focus();
        }
    };

    const handleOtpKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            otpRefs.current[index - 1]?.focus();
        }
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setError(t("Please enter your email"));
            return;
        }
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            setError(t("Please enter a valid email"));
            return;
        }

        setStep(2);
        setError("");
    };

    const handleResetSubmit = (e) => {
        e.preventDefault();
        const code = otp.join("");

        if (code.length !== 6) {
            setError(t("Please enter the 6-digit code"));
            return;
        }
        if (newPassword !== confirmPassword) {
            setError(t("Passwords do not match"));
            return;
        }
        if (newPassword.length < 8) {
            setError(t("Password must be at least 8 characters"));
            return;
        }

        onSubmit({
            email: email,
            code: code,
            new_password: newPassword,
            new_password2: confirmPassword
        });
    };

    if (!isOpen) return null;

    return (
        <div className={styles.mainModalConteiner}>
            <form onSubmit={step === 1 ? handleEmailSubmit : handleResetSubmit} className={styles.formaBlock}>
                <div className={styles.resetConteiner}>
                    <span className={styles.resetTitle}>
                        {step === 1 ? t("Forgot password") : t("Reset password")}
                    </span>
                    <button
                        type="button"
                        onClick={onClose}
                        className={styles.buttonBlock}
                        aria-label="Close the dialog"
                    >
                        <div className={styles.closeImgBtn}>
                            <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                <path d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z" />
                            </svg>
                        </div>
                    </button>
                </div>

                {step === 1 ? (
                    <>
                        <div className={styles.descriptionText}>
                            {t("Enter your email for the verification process, we will send 6-digit code to your email.")}
                        </div>

                        <div className={styles.fieldEmail}>
                            <div className={styles.fieldCont}>
                                <input
                                    type="email"
                                    placeholder=" "
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={styles.fieldInp}
                                />
                                <label className={styles.lable}>{t("Email")}</label>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.otpContainer}>
                            <p className={styles.otpLabel}>{t("6-digit verification code")}</p>
                            <div className={styles.otpInputs}>
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                        ref={(el) => (otpRefs.current[index] = el)}
                                        className={styles.otpInput}
                                        autoFocus={index === 0}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className={styles.fieldEmail}>
                            <div className={styles.fieldCont}>
                                <input
                                    type="password"
                                    placeholder=" "
                                    autoComplete="new-password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className={styles.fieldInp}
                                />
                                <label className={styles.lable}>{t("New password")}</label>
                            </div>
                        </div>

                        <div className={styles.fieldEmail}>
                            <div className={styles.fieldCont}>
                                <input
                                    type="password"
                                    placeholder=" "
                                    autoComplete="new-password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className={styles.fieldInp}
                                />
                                <label className={styles.lable}>{t("Confirm new password")}</label>
                            </div>
                        </div>
                    </>
                )}

                {error && <div className={styles.errorText}>{error}</div>}

                <button type="submit" className={styles.submitBtn}>
                    <div className={styles.textSub}>
                        {step === 1 ? t("Send Code") : t("Reset Password")}
                    </div>
                </button>
            </form>
        </div>
    );
};

export default ModalReset;