import api from "../api";

export const deleteOwnerRoleInfo = async (id) => {
    console.log(id)
    try {
        const users = await api.delete(`/owner_site/verifications/users/client-status-roles/${id}/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}