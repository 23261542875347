import React, {useState} from 'react';
import styles from './ModalAccess.module.scss'
import {observer} from "mobx-react";
import {CloseIcon} from "../../../assets/icons";
import rootStore from "../../../store/RootStore";
import OtpInput from "./OtpInput";

const ModalAccess = observer(({username, setIsOpen}) => {
    const [code, setCode] = useState("")
    const {registerCode, userData, errorCodeText} = rootStore.userStore;
    const [errorOpen, setErrorOpen] = useState(false);
    const handleChange = (e) => {
        setCode(e.target.value)
    }
    const handleSendCode = async () => {
        const result = await registerCode(userData.profile.username, code);
        console.log(result)
        if (result) {
            setIsOpen(false)
        } else {
            setErrorOpen(true)
        }
    }

    return (
        <div className={styles.madolMain}>
            <div className={styles.modalConteiner} style={errorOpen ? {height: "16rem"} : {height: "13rem"}}>
                <div className={styles.topPanel}>
                    <div onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <div className={styles.mainConeiner}>
                    <span className={styles.textTitle}>Access code</span>
                    <span className={styles.textInfo}>A password will be send to the specified email address</span>
                    <OtpInput length={6} onComplete={setCode} />
                </div>
                <button className={styles.btnActive} onClick={() => handleSendCode(true)}>
                    <div className={styles.textSub}>Confirm</div>
                </button>
                {errorOpen && (<div className={styles.errorText}>{errorCodeText}</div>)}
            </div>
        </div>
    );
});

export default ModalAccess;