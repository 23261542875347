import api from "../api";

export const getUserSearchHistory = async () => {
  try {
    const history = await api.get(`/search-user-history/`);
    return history.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
