import api from "../api";

export const addOwnerSearchTag = async (orderNumber,name) => {
    try {
        await api.post(
            `/owner_site/shops/search/o/tags/`,
            { order_number: orderNumber, name: name }
        )
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
};