import React from 'react';

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import styles from './CreditsTokens.module.scss'

import img from "../../components/PersonalLab/RedeemCodeTab/img.png";
import IconBack from "../../components/Icons/IconBack";
import img_dark from "../../components/PersonalLab/RedeemCodeTab/img_dark.png";
import { useTheme } from "../../helpers/ThemeContext";

const CreditsTokens = () => {
    const {themeMode} = useTheme();
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/discovery/me', { replace: true });
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL)
            .then(() => {
                console.log("Ссылка скопирована")
            })
            .catch((err) => {
                console.error('Ошибка копирования: ', err);
            });
    };

    return (
        <>
            <div className={styles.goBackButton} onClick={goBack}>
                <IconBack size={20} pure={true} fill={"#1b5e3b"}/>
            </div>
            <div className={styles.lvl1}>
                <div className={styles.lvl2}>
                    <div className={styles.lvl3}>
                        <div className={styles.mainConteiner}>
                            <div className={styles.mainBlock}>
                                <div className={styles.mainImg}>
                                    <div className={styles.mainBlockImg}>
                                        {themeMode === "light" ? <img style={{width: "-webkit-fill-available", objectFit:"cover"}}  src={img} alt="React Logo"/> :
                                            <img style={{width: "-webkit-fill-available", objectFit:"cover"}} src={img_dark} alt="React Logo"/>}
                                    </div>
                                </div>
                                <div className={styles.container}>
                                    <h2 className={styles.title}>
                                        <span>{t("Want to pay less?")}</span>
                                    </h2>
                                    <div className={styles.description}>
                                        <p className={styles.descText}>
                                            <span>{t("You can invite friends to Wolt. When a friend makes their first order, you'll get a discount.")}</span>
                                        </p>
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <button
                                            aria-disabled="false"
                                            type="button"
                                            className={styles.button}
                                            data-localization-key="user.redeem"
                                            onClick={() => copyToClipboard()}
                                        >
                                            <div className={styles.buttonText}>{t("Invite friends")}</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreditsTokens;