import React, { useEffect, useState } from 'react';
import styles from './OrderCardActual.module.scss';
import { useNavigate } from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";
import { YMaps, Map } from '@pbe/react-yandex-maps';
import PromoCodeModal from "./PromoCodeModal/PromoCodeModal";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import rootStore from "../../store/RootStore";
import { observer } from "mobx-react";
import LocationAdress from "./LocationAdress/LocationAdress";
import AddressInfo from "./AddressInfo/AddressInfo";
import DeliveryTimes from "./DeliveryTimes/DeliveryTimes";
import { getOrder } from "../../helpers";
import ItemsModal from "./ItemsModal/ItemsModal";
import AcceptModal from "../../components/AcceptModal/AcceptModal";
import { getCurrencySymbol } from "../../helpers/getCurrency";
import { t } from "i18next";
import {IconCaretUpOutline} from "../../components/Icons/IconCaretUpOutline";

const OrderCardActual = () => {
    const navigate = useNavigate();
    const [openPromo, setOpenPromo] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openAcceptModal, setOpenAcceptModal] = useState(false);
    const [openItems, setOpenItems] = useState(false);
    const symbol = getCurrencySymbol();
    const [actionState, setActionState] = useState();

    const [mobile, setMobile] = useState('');
    const [comment, setComment] = useState('');
    const [needPhone, setNeedPhone] = useState(true);

    const [isCheckout, setCheckout] = useState(false);
    const [isErrorCash, setErrorCash] = useState(false);

    const [openDelivery, setOpenDelivery] = useState(false);

    const { getCheckout, lazyInitialize, sendCheckout, addressData, locationData } = rootStore.checkoutStore;
    const { getTargetOrder, acceptCourierOrder, acceptOrder, finishOrder, cancelOrder } = rootStore.curierStore;
    const { confirmOrders, cancelModOrders } = rootStore.ordersModerStore;
    const isCurier = window.location.pathname.includes('curier-panel');
    const isHistory = window.location.pathname.includes('order-history');
    const isActual = window.location.pathname.includes('actual');
    const isWorked = window.location.pathname.includes('worked');
    const isModerator = window.location.pathname.includes('moderator');
    const [data, setData] = useState(JSON.parse(getOrder()));
    const [isHidden, setIsHidden] = useState(false);

    const toggleVisibility = () => {
        setIsHidden(!isHidden);
    };

    const handleClickLocation = () => {
        setOpenLocation(true);
    };
    // TODO: lazyInitialize, Судя по всему лишний запрос /checkout-preview/, закомментил
    // useEffect(() => {
    //     lazyInitialize();
    // }, []);

    const handleSubmit = async () => {
        if (mobile) {
            const data = {
                address: `${locationData} ${addressData.buildingName} ${addressData.enterance} ${addressData.floor} ${addressData.Apartment}`,
                phone_number: mobile,
            };
        } else {
            setNeedPhone(true);
            setTimeout(() => {
                setNeedPhone(false);
            }, 2000);
        }
        if (isActual) {
            acceptCourierOrder(data.id);
            setCheckout(true);
            setTimeout(() => {
                navigate('/curier-panel/worked');
            }, 500);
        }
        if (isWorked) {
            finishOrder(data.id);
            setCheckout(true);
            setTimeout(() => {
                navigate('/curier-panel/all');
            }, 500);
        }
        if (isModerator) {
            confirmOrders(data.shop, data.id);
            navigate(-1);
        }
    };

    const handleActive = (state) => {
        setActionState(state);
        setOpenAcceptModal(true);
    };

    const handleCancelSubmit = async () => {
        if (!isModerator) {
            cancelOrder(data.id);
            navigate('/curier-panel/all');
        } else {
            cancelModOrders(data.shop, data.id);
            navigate(-1);
        }
    };

    if (isCheckout && !isCurier) {
        setTimeout(() => {
            navigate('/discovery/me/order-history');
        }, 2000);
    }
    if (isErrorCash) {
        setTimeout(() => {
            setErrorCash(false);
        }, 2000);
    }

    const dataAddress = JSON.parse(data?.delivery_address_text);
    const items = JSON.parse(data?.items);
    const currency = data?.currency;

    const handleCloseAcceptModal = () => {
        setOpenAcceptModal(false);
    }

    return (
        <>
            <div className={styles.mainPage}>
                    <div className={styles.mapBlockWithoutMap}>
                        <div className={styles.backer}
                            onClick={() => navigate("/curier-panel/actual")}>
                            <IconBack size={20} pure={true} fill={"#1b5e3b"} marg={0}/>
                        </div>
                    </div>
                <div className={`${styles.mainConteiner} ${isHidden ? styles.hidden : ''}`}>
                    <div className={styles.shopNamspan}>
                        <span className={styles["shopName__title"]}>
                            {t("New delivery from")}
                        </span>
                        <span className={styles["shopName__description"]}>  {data.shop_name}</span>
                    </div>
                    <div className={styles.statusBlock}>
                        {data.status}
                    </div>
                    <div className={styles["delivery--address"]}>
                        <div className={styles.dot}>&bull;</div>
                        <div>
                            <span className={styles.deliveryAddressText}>{dataAddress?.district}</span>
                            <span className={styles.deliveryAddressText}>{dataAddress?.street}</span>
                            <span className={styles.deliveryAddressText}>{dataAddress?.house_number}</span>
                            <span className={styles.deliveryAddressText}>{dataAddress?.entrance_number}</span>
                        </div>
                    </div>
                    <hr style={{opacity: 0.3, margin: "1rem 0", position: "relative", width: "100%"}}/>
                    <div className={styles.salary}>
                        <p className={styles.salaryMoney}> {data?.courier_commission} $</p>
                        <p className={styles.salaryText}> {t("Expected income(may be higher)")}</p>
                    </div>
                    <button aria-disabled="false" type="button"
                            className={`${styles.footerBtn} ${isCheckout && styles.good} ${isErrorCash && styles.error}`}
                            onClick={() => handleActive(true)}>
                        <div className={styles.footerBtnMain}>
                            {isActual && !isCheckout && t("Get to work")}
                            {isWorked && !isCheckout && t("Success")}
                            {isCheckout && t("Success")}
                            {isModerator && t("Confirm")}
                        </div>
                    </button>
                </div>

                <PromoCodeModal isOpen={openPromo} setIsOpen={setOpenPromo}/>
                <PaymentMethods isOpen={openPayment} setIsOpen={setOpenPayment}/>
                <LocationAdress isOpen={openLocation && !isCurier && !isModerator} setIsOpen={setOpenLocation}/>
                <AddressInfo isOpen={openLocation && (isCurier || isModerator)} setIsOpen={setOpenLocation}
                             dataAddress={dataAddress}/>
                <DeliveryTimes isOpen={openDelivery} setIsOpen={setOpenDelivery}/>
                <ItemsModal isOpen={openItems} setIsOpen={setOpenItems} items={items} currency={currency}/>
                {openAcceptModal && (
                    <AcceptModal 
                        action={actionState}
                        handleClose={handleCloseAcceptModal}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancelSubmit}
                    />
                )}
            </div>
            {isHidden && <div style={{position: "absolute", left: "7vw", top: "93vh"}} onClick={toggleVisibility}>
                <IconCaretUpOutline/>
            </div>}
        </>
    );
};

export default observer(OrderCardActual);
