import api from "../api";

export const resetPassword = async (data) => {
    try {
        await api.post(
            `/user/reset-passowrd-email/`,
            data
        )
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
};
