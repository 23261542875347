import * as React from 'react';
import styles from './NewProductModal.module.scss'
import {unknownImage} from "../../../../helpers";
import NewIconDown from "../NewIconDown/NewIconDown";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {getCurrencySymbol} from "../../../../helpers/getCurrency";
import NewProductSearchCard from "../../NewProductSearchCard";

function NewProductModal({product, action}) {
    const navigate = useNavigate();
    return (
        <div className={styles.mainConteiner}>
            <div className={styles.contentBlock}>
                <div className={styles.imgBlock}>
                    <img className={styles.imageOrder}
                         src={process.env.REACT_APP_MEDIA_URL + product.product_images[0].ImageUrl || unknownImage}
                         alt="ProductImage"/>
                    <NewIconDown stateClose={action}/>
                </div>
                <div className={styles.contentBlockMain}>
                    <div className={styles.topContent}>
                        <span className={styles.productName}>{product.ProductName}</span>
                        <span className={styles.productCost}>{product.Price} {getCurrencySymbol()}</span>
                        <span className={styles.productDescription}>{product.Product_info}</span>
                    </div>
                </div>
                <div className={styles.conteinerInfo}>
                    <span className={styles.titleDown}>{t("ORDER FROM")}</span>
                    <NewProductSearchCard shop={product.shop}/>
                </div>
                <button className={styles.mainBtn} onClick={() => navigate(`/stores/${product.shop.ShopID}`)}>
                    <span className={styles["add-button--element"]}>{t("Start order")}</span>
                </button>
            </div>
        </div>
    );
}
export default NewProductModal;